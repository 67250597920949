import { Component } from '@angular/core';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { CoreService } from '../core.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

@Component({
    selector: 'hss-password-change',
    templateUrl: 'password-change.component.html'
})

export class PasswordChangeComponent implements OnInit {
    currentUser: any;
    introText: string;
    passwordOld: string;
    passwordNew: string;
    passwordNewRepeat: string;
    private readonly minPasswordLength = 8;
    //
    isSubmitting = false;
    hasSubmitted = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private coreService: CoreService,
        private logger: LoggingService,
        public translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
        //  Must use timeout for translations to apply (even if set to ridiculously small value like 25ms), just 'cause:
        setTimeout(() => {
            this.introText = this.translate.instant('STRING.PASSWORD.RESET.DESCRIPTION') + '<br>' +
                this.translate.instant('STRING.PASSWORD.REQUIREMENTS', { 'numChars': this.minPasswordLength });
        }, 25);
    }

    onSubmitPasswordChanged(event): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        event.preventDefault();
        if (!this.passwordOld) {
            field = this.translate.instant('STRING.PASSWORD.OLD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  'New password' filled in:
        if (!this.passwordNew) {
            field = this.translate.instant('STRING.PASSWORD.NEW');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  'Repeat new password' filled in:
        if (!this.passwordNewRepeat) {
            field = this.translate.instant('STRING.PASSWORD.NEW.REPEAT');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password too short:
        if (this.passwordNew && this.passwordNew.length < this.minPasswordLength) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.TOO_SHORT',
                {'field': field, 'minLength': this.minPasswordLength}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password invalid:
        if (this.passwordNew && !this.coreService.isValidPassword(this.passwordNew)) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.REQUIREMENTS_NOT_MET', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password and Repeat Password not equal:
        if ((this.passwordNew && this.passwordNewRepeat) && (this.passwordNew !== this.passwordNewRepeat)) {
            field = this.translate.instant('STRING.PASSWORD');
            let field2 = this.translate.instant('STRING.PASSWORD.REPEAT');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_EQUAL', {'field': field, 'field2': field2}) + '</span>';
            this.feedbackVisible = true;
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitPasswordChanged();
        }
    }

    doSubmitPasswordChanged(): void {
        let postData = {
            currentPassword: this.passwordOld,
            newPassword: this.passwordNew,
            passwordRepeat: this.passwordNewRepeat
        };
        //  Request:
        this.isSubmitting = true;
        let passwordResetObservable = this.userService.changeUserPassword(postData);
        passwordResetObservable
            .pipe(finalize(() =>
                setTimeout(() => {
                    this.isSubmitting = false;
                }, 500)
            ))
            .subscribe(
                (response) => {
                    this.logger.print('Submit PasswordChange succeeded: ', response, 'log');
                    this.feedbackClass = 'success';
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.CHANGE.SUBMIT.SUCCESS') + '</span>';
                    this.feedbackVisible = true;
                    //  Disable 'Submit'-button after successful submission:
                    this.hasSubmitted = true;
                },
                (error) => {
                    this.logger.print('Submit PasswordChange failed: ', error, 'error');
                    if (error.status) {
                        if (error.statusText.toLowerCase() === 'bad request') {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.CHANGE.SUBMIT.ERROR.INVALID') + '</span>';
                        } else {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.CHANGE.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                        }
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.CHANGE.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                    //  Enable 'Submit'-button after failed submission:
                    this.hasSubmitted = false;
                },
                () => {}
            );
    }

    navigate(route: string): void {
        this.router.navigate(['/' + route]);
    }
}
