import { Component, Input } from '@angular/core';
import { OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoggingService } from '@services/logging.service';
import { UserService } from '@services/user.service';

@Component({
    selector: 'hss-dashboard-userinfo, [dashboardUserInfo]',
    templateUrl: 'dashboard-userinfo.component.html'
})

export class DashboardUserInfoComponent implements OnInit, OnChanges {
    @Input() currentUser: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private logger: LoggingService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {}

    ngOnChanges(): void {
        //  Load Profile data:
        this.currentUser = this.userService.currentUser; // .getUser();
        this.logger.print('Current User', this.currentUser, 'log');
    }

    getMugshotStyle(): object {
        return { 'background-image': 'url(' + this.currentUser.image + ')' };
    }

    //  Navigate:
    navigate(route: string): void {
        this.router.navigate(['/' + route]);
    }
}
