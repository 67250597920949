import { isDevMode } from '@angular/core';
import { Component, Input } from '@angular/core';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { AuthenticationService } from '@services/authentication.service';
import { ChatService } from '@module.chat/chat.service';
import { LoggingService } from '@services/logging.service';
import { SocketService } from '@services/socket.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-dashboard-navigation, [dashboardNavigation]',
    templateUrl: 'dashboard-navigation.component.html'
})

export class DashboardNavigationComponent implements OnInit, OnDestroy {
    @Input() navigationVisible: boolean;
    public currentUser: any = null;
    public isVolunteer: boolean;
    public currentLanguage: string;
    public contactRequestCount: number;
    public unreadMessages: number;
    public hsnUrl: string;
    private chatObservable = null;
    public isDevMode: boolean;
    //
    private toastrOptions = {
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-center',
        toastClass: 'toast toast-message',
        preventDuplicates: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        tapToDismiss: false,
        timeOut: 7500,
        extendedTimeOut: 3750
    } as any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private chatService: ChatService,
        private logger: LoggingService,
        private socketService: SocketService,
        private toastr: ToastrService,
        public translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.initUserData();
        //  Real-time Chat:
        this.initIncomingMessageStream();
        //  Set HartslagNu Url:
        this.hsnUrl = environment.c_hsnUrl;
        //  Hide menu by default:
        this.navigationVisible = false;
        //  Dev Mode?
        this.isDevMode = isDevMode();
    }

    ngOnDestroy(): void {
        this.toastr.clear();
    }

    initUserData(): void {
        this.currentUser = this.userService.getUser();
        this.isVolunteer = this.userService.isVolunteer();
        //  Set User Language:
        this.currentLanguage = this.currentUser.preferredLanguage.toLowerCase();
        //  Set 'Open ContactRequests'-counter:
        this.contactRequestCount = this.currentUser.contactRequestCount;
        //  Set 'Unread Messages'-counter:
        this.unreadMessages = this.currentUser.unreadMessages;
        //  Subscribe 'Unread Messages' / 'Open ContactRequests' counters to User BehaviorSubject (for real-time updates):
        this.userService.getUserSubject().subscribe(user => {
            if (user) {
                this.contactRequestCount = user.contactRequestCount;
                this.unreadMessages = user.unreadMessages;
            }
        });
    }

    //  Initialize incoming Message stream:
    initIncomingMessageStream(): void {
        if (!this.socketService.chatObservable) {
            this.socketService.connectToChat();
            let payload = {token: this.authenticationService.getAccessToken()};
            this.socketService.onLogIntoChat(payload);
            this.socketService.onReceiveMessage();
            //
            this.chatObservable = this.socketService.chatObservable;
            this.chatObservable
                .subscribe(
                (response) => {
                    this.handleIncomingMessage(response);
                },
                () => {},
                () => {}
            );
        }
    }

    //  Handle incoming Message:
    handleIncomingMessage(messageObj: any) {
        //  Is CurrentUser in Chat when Message is incoming?
        if (this.router.url.includes('/dashboard/inbox')) {
            //  Yes => Emit incoming Message to ChatService (which in turn will send data to ChatWindow Component):
            if (this.chatService.activeConversationId !== messageObj.conversationId) {
                this.updateCurrentUserUnreadMessages(250);
            }
            this.chatService.emitIncomingMessage(messageObj);
        } else {
            //  No => ShowToastr Message with incoming Message:
            let title = this.translate.instant('STRING.CHAT.NEW.MESSAGE.FROM', {name: messageObj.fullName});
            let message = messageObj.message;
            this.toastr.show(message, title, this.toastrOptions)
                .onTap
                .pipe(take(1))
                .subscribe(
                    () => {
                        this.onToastClick(messageObj.conversationId);
                    },
                    () => {},
                    () => {}
                );
            //  Update Unread Messages:
            this.updateCurrentUserUnreadMessages(500);
        }
    }

    updateCurrentUserUnreadMessages(delay: number): void {
        setTimeout( () => {
            this.currentUser.unreadMessages++;
            this.userService.setUser(this.currentUser);
        }, delay);
    }

    //  Event handler for clicking on 'New Message'-Toast, to redirect CurrentUser to correct Conversation:
    onToastClick(conversationId: number): void {
        let navigationExtras: NavigationExtras = { state: { conversationId: conversationId }};
        this.router.navigate(['/dashboard/inbox'], navigationExtras);
    }

    //  Log out current user/end current session:
    logOut(): void {
        let logoutObservable = this.authenticationService.logout();
        logoutObservable
            .subscribe(
                (response) => {
                    this.logger.print('Logout succeeded', response, 'log');
                    this.authenticationService.clearAuthObj();
                    this.router.navigate(['/login']);
                },
                (error) => {
                    this.logger.print('Logout failed', error, 'error');
                    this.authenticationService.clearAuthObj();
                    this.router.navigate(['/login']);
                },
                () => {
                    this.socketService.onLogOutOfChat();
                    location.reload();
                }
            );
    }

    toggleNavigation(): void {
        this.navigationVisible = !this.navigationVisible;
    }

    //  Navigate (slightly delayed to allow sidebar to hide before navigating):
    navigate(route: string): void {
        //  Close menu before navigating:
        this.toggleNavigation();
        //  Navigate once animation is (almost) complete:
        setTimeout(() => {
            this.router.navigate(['/' + route]);
        }, 500);
    }

    //  DEV:
    //  Switch language
    switchLanguage(language: string): void {
        this.currentLanguage = language;
        this.userService.setUserLanguage(language);
    }
}
