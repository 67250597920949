import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

import { AuthenticationService } from '@services/authentication.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import { User } from '@models/user.model';

@Component({
    selector: 'hss-login-with-token',
    templateUrl: 'login-with-token.component.html'
})

export class LoginWithTokenComponent implements OnInit {
    currentUser: User;
    hsnToken = null; // URL parameter
    alarmId = 0; // URL parameter
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private logger: LoggingService,
        private translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Get Token:
        // this.hsnToken = this.activatedRoute.snapshot.paramMap.get('id');
        this.activatedRoute.queryParams.subscribe(params => {
            this.hsnToken = params['id'];
            if (parseInt(params['alarmId'], 10) > 0) {
                this.alarmId = params['alarmId'];
            }
        });
        //  Validate HSN Token:
        this.onSubmitHsnToken();
    }

    //  Check if all requirements to validate HSN Token are met:
    onSubmitHsnToken(): void {
        if (this.hsnToken) {
            //  Token set => Validate HSN Token:
            this.doSubmitHsnToken();
        } else {
            //  Token not set => redirect to normal login:
            this.logger.print('HartslagNu Token not set: redirect to Login', '', 'warn');
            let navigationExtras: NavigationExtras = { state: { hsnTokenInValid: true }};
            this.router.navigate(['/login'], navigationExtras);
        }
    }

    //  Validate HSN Token:
    doSubmitHsnToken(): void {
        //  Requests:
        //  STEP 1: CHECK LOGIN CREDENTIALS:
        let loginWithTokenObservable = this.authenticationService.loginWithToken(this.hsnToken);
        loginWithTokenObservable.subscribe(
            (response) => {
                this.logger.print('Login with HSN Token succeeded', response, 'log');
                //  Store Auth Object in LocalStorage:
                this.authenticationService.setAuthObject(response);
                //  STEP 2: Get User data:
                let userObservable = this.userService.getUserData();
                userObservable.subscribe(
                    (userData) => {
                        this.logger.print('GetUserData succeeded', userData, 'log');
                        //  Check if Role 'Volunteer' is present (anyone accessing through HSN should have that role):
                        if (userData['roles'].indexOf('volunteer') === -1 && userData['roles'].indexOf('ROLE_VOLUNTEER') === -1) {
                            userData['roles'].push('volunteer');
                        }
                        this.userService.setUser(userData);
                        //  Redirect to specific Incident, if required:
                        let incidentId = '';
                        if (this.alarmId > 0) {
                            incidentId = '/' + this.alarmId;
                        }
                        this.router.navigate(['/dashboard/incidents' + incidentId]);
                    },
                    (error) => {
                        this.logger.print('GetUserData failed', error, 'error');
                        let navigationExtras: NavigationExtras = {state: {hsnTokenInValid: true}};
                        this.router.navigate(['/login'], navigationExtras);
                    },
                    () => {
                    }
                );
            },
            (error) => {
                this.logger.print('Login with HSN Token failed', error, 'error');
                let navigationExtras: NavigationExtras = {state: {hsnTokenInValid: true}};
                this.router.navigate(['/login'], navigationExtras);
            },
            () => {}
        );
    }

    navigate(route): void {
        this.router.navigate([route]);
    }
}
