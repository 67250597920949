import { Component } from '@angular/core';
import { AfterViewInit, OnInit } from '@angular/core';

import { DateService } from '@services/date.service';
import {MalihuScrollbarService} from 'ngx-malihu-scrollbar';

@Component({
    selector: 'hss-styleguide',
    templateUrl: 'styleguide.component.html'
})

export class StyleGuideComponent implements OnInit, AfterViewInit {
    public currentDate: any;
    public materialTextboxValue = '';
    public materialTextboxPrefilledValue = 'Pre-filled textbox value';
    public materialTextareaValue = '';
    public materialTextareaPrefilledValue = 'Pre-filled textarea value';
    public isLoading = false;
    //
    private scrollbarOptions = {
        axis: 'y',
        theme: 'custom-theme',
        alwaysShowScrollbar: 1,
        mouseWheel: { enable: true},
        scrollButtons: { enable: false }
    } as any;

    constructor(
        public dateService: DateService,
        private mScrollbarService: MalihuScrollbarService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        this.currentDate = this.dateService.toLocalShortUSDate(new Date());
    }

    ngAfterViewInit() {
        this.mScrollbarService.initScrollbar('#chat-conversation-01', this.scrollbarOptions);
        this.mScrollbarService.initScrollbar('#chat-conversation-02', this.scrollbarOptions);
        this.mScrollbarService.initScrollbar('#chat-conversation-03', this.scrollbarOptions);
    }

    submitLoadingButton(): void {
        this.isLoading = true;
        setTimeout( () => {
            this.isLoading = false;
        }, 2500);
    }
}
