import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ApiService } from '@services/api.service';

import { IncidentUser } from './incident-user.model';

@Injectable()
export class IncidentService {

    constructor(
        private http: HttpClient,
        private injector: Injector,
        private router: Router,
        private apiService: ApiService
    ) {}

    //  Get all Incidents of current User:
    getIncidents(): Observable<Response> {
        //  Request options:
        let endpoint = '/incidents';
        let type = 'GET';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Add CurrentUser with ID 'userId' to Incident with ID 'uniqueId':
    addIncident(postData: any, userId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/users/' + userId + '/add-incident';
        let type = 'PUT';
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }

    //  Request code for Incident:
    requestIncidentCode(postData: any): Observable<Response> {
        //  Request options:
        let endpoint = '/incident-token-requests/user';
        let type = 'POST';
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }

    //  Get all IncidentUsers of Incident with ID 'incidentId':
    getIncidentUsers(incidentId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/incidents/' + incidentId + '/users';
        let type = 'GET';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Update IncidentUser Role for Incident with ID 'incidentId':
    updateIncidentUserRole(incidentRole: string, userIncidentId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/user-incidents/' + userIncidentId + '/update-role';
        let type = 'PUT';
        let postData = {
            incidentRole: incidentRole
        };
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }

    //  Send outgoing ContactRequest to user 'receiverUserIncident' with reason 'reason':
    sendContactRequest(reason: string, receiverUserIncident: IncidentUser): Observable<Response> {
        //  Request options:
        let endpoint = '/contact-requests';
        let type = 'POST';
        let postData = {
            reason: reason,
            receiverUserIncident: receiverUserIncident.userIncident
        };
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }

    //  Confirm incoming ContactRequest with ID 'contactRequestId':
    confirmContactRequest(receiverUserIncident: IncidentUser): Observable<Response> {
        //  Request options:
        let endpoint = '/contact-requests/' + receiverUserIncident.contactRequestId + '/confirm';
        let type = 'PUT';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Decline incoming ContactRequest with ID 'contactRequestId':
    declineContactRequest(reason: string, receiverUserIncident: IncidentUser): Observable<Response> {
        //  Request options:
        let endpoint = '/contact-requests/' + receiverUserIncident.contactRequestId + '/decline';
        let type = 'PUT';
        let postData = {
            declineReason: reason
        };
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }
}
