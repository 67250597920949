export class IncidentUser {
    contactRequest: string; // (reference to ContactRequest between this IncidentUser and CurrentUser) - required for confirming/declining ContactRequest
    contactRequestId: number; // (id of ContactRequest between this IncidentUser and CurrentUser) - required for confirming/declining ContactRequest
    contactStatus: string; // (status of relation between 2 users)
    inOrOut: string; // (direction of contactStatus ['out' = contactStatus initiated by CurrentUser, 'in' = contactStatus initiated by the other User])
    declinedCount: number; // (number of declined contactRequests)
    conversation: string; // (id of Conversation between this IncidentUser and CurrentUser) - required for redirect CurrentUser to correct Chat Conversation
    conversationId: number; // (reference to Conversation between this IncidentUser and CurrentUser) - required for redirect CurrentUser to correct Chat Conversation
    fullName: string;
    incidentRole: any;
    userIncident: string; // (reference to self) - required for sending ContactRequest
    userIncidentId: number; // (id of self) - required for sending ContactRequest

    constructor(userObj) {
        this.contactRequest = userObj.contactRequest;
        this.contactRequestId = userObj.contactRequestId;
        this.contactStatus = userObj.contactStatus;
        this.inOrOut = userObj.inOrOut;
        this.declinedCount = (userObj.declinedCount) ? userObj.declinedCount : 0;
        this.conversation = userObj.conversation;
        this.conversationId = userObj.conversationId;
        this.fullName = userObj.fullName;
        this.incidentRole = userObj.incidentRole;
        this.userIncident = userObj.userIncident;
        this.userIncidentId = userObj.userIncidentId;
    }
}
