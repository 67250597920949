export class Message {
    id: number;
    createdAt: string;
    content: any;
    isMe: boolean;
    isRead: boolean;
    isFirstOfDay: boolean;

    constructor(messageObj) {
        this.id = messageObj.id;
        this.createdAt = messageObj.createdAt;
        this.content = messageObj.content;
        this.isMe = messageObj.isMe;
        this.isRead = messageObj.isRead;
        this.isFirstOfDay = messageObj.isFirstOfDay;
    }
}
