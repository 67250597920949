import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthenticationGuard implements CanActivate {
    currentRoute: string;

    constructor(
        private router: Router
    ) {
        this.currentRoute = this.router.url;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            return true;
        } else {
            //  Add current Uul as queryParam (so we can return User to Route of origin after logging in):
            let queryParams = { queryParams: { returnUrl: state.url }};
            this.router.navigate(['/login'], queryParams);
            return false;
        }
    }
}
