import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { finalize } from 'rxjs/operators';

import { CoreService } from '../core.service';
import { LoggingService } from '@services/logging.service';
import {TranslateService} from '@ngx-translate/core';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-register',
    templateUrl: 'register.component.html'
})

export class RegisterComponent implements OnInit {
    introText: string;
    email: string;
    password: string;
    passwordRepeat: string;
    private readonly minPasswordLength = 8;
    //
    isSubmitting = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;
    //
    public readonly hssRegisterUrl = environment.c_hssRegisterUrl;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private coreService: CoreService,
        private logger: LoggingService,
        public translate: TranslateService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Must use timeout for translations to apply (even if set to ridiculously small value like 25ms), just 'cause:
        setTimeout(() => {
            //  Intro text:
            this.introText = this.translate.instant('STRING.REGISTER.DESCRIPTION') + '<br>' +
                this.translate.instant('STRING.PASSWORD.REQUIREMENTS', { 'numChars': this.minPasswordLength });
        }, 25);
    }

    onSubmitRegistration(): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        event.preventDefault();
        //  Per-field Input Validation:
        if (!this.email) {
            field = this.translate.instant('STRING.EMAIL');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.password) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.passwordRepeat) {
            field = this.translate.instant('STRING.PASSWORD.REPEAT');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password too short:
        if (this.password && this.password.length < this.minPasswordLength) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.TOO_SHORT',
                {'field': field, 'minLength': this.minPasswordLength}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password invalid:
        if (this.password && !this.coreService.isValidPassword(this.password)) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.REQUIREMENTS_NOT_MET', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password and Repeat Password not equal:
        if ((this.password && this.passwordRepeat) && (this.password !== this.passwordRepeat)) {
            field = this.translate.instant('STRING.PASSWORD');
            let field2 = this.translate.instant('STRING.PASSWORD.REPEAT');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_EQUAL', {'field': field, 'field2': field2}) + '</span>';
            this.feedbackVisible = true;
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitRegistration();
        }
    }

    doSubmitRegistration(): void {
        let postData = {
            email: this.email,
            password: this.password,
            passwordRepeat: this.passwordRepeat
        };
        //  Request:
        this.isSubmitting = true;
        let registerObservable = this.coreService.submitRegistration(postData);
        registerObservable
            .pipe(finalize(() =>
                setTimeout(() => {
                    this.isSubmitting = false;
                }, 500)
            ))
            .subscribe(
                (response) => {
                    this.logger.print('Submit Registration succeeded', response, 'log');
                    this.feedbackClass = 'success';
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.REGISTER.SUBMIT.SUCCESS') + '</span>';
                    this.feedbackVisible = true;
                },
                (error) => {
                    this.logger.print('Submit Registration failed', error, 'error');
                    if (error.status) {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.REGISTER.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.REGISTER.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                },
                () => {}
            );
    }

    navigate(route): void {
        this.router.navigate([route]);
    }
}
