import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';

import { ChatCoreComponent } from './chat-core/chat-core.component';
import { ChatSearchComponent } from './chat-search/chat-search.component';
import { ConversationListItemComponent } from './conversation-list-item/conversation-list-item.component';
import { ConversationResultItemComponent } from './conversation-result-item/conversation-result-item.component';
import { ConversationWindowComponent } from './conversation-window/conversation-window.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { ChatService } from './chat.service';

import { throwIfAlreadyLoaded } from '@guards/module-import.guard';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/' , '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SweetAlert2Module
    ],
    exports: [
        ChatCoreComponent,
        ChatSearchComponent,
        ConversationListItemComponent,
        ConversationResultItemComponent,
        ConversationWindowComponent
    ],
    declarations: [
        ChatCoreComponent,
        ChatSearchComponent,
        ConversationListItemComponent,
        ConversationResultItemComponent,
        ConversationWindowComponent
    ],
    providers: [
        ChatService
    ],
})

export class ChatModule {
    constructor( @Optional() @SkipSelf() parentModule: ChatModule) {
        throwIfAlreadyLoaded(parentModule, 'PublicModule');
    }
}
