import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ApiService } from '@services/api.service';

@Injectable()
export class CoreService {

    constructor(
        private router: Router,
        private apiService: ApiService
    ) {}

    //  Request new Password (public):
    requestPassword(postData: any): Observable<Response> {
        //  Request options:
        let endpoint = '/users/forgot-password';
        let type = 'POST';
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Reset new Password (public):
    resetPassword(postData: any): Observable<Response> {
        //  Request options:
        let endpoint = '/users/reset-password';
        let type = 'POST';
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Reset new Password (public):
    submitRegistration(postData: any): Observable<Response> {
        //  Request options:
        let endpoint = '/users/register';
        let type = 'POST';
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Submit Contact Form (public):
    submitContact(postData: any): Observable<Response> {
        //  Request options:
        let endpoint = '/contact';
        let type = 'POST';
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Submit Contact Form (authenticated):
    submitContactAuthenticated(postData: any): Observable<Response> {
        //  Request options:
        let endpoint = '/contact/authenticated';
        let type = 'POST';
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }


    //  Helpers:
    //  Check if password is valid:
    isValidPassword(password: string): boolean {
        let regex = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\-\|\.,:;\?!@#\$%\^&\*_=\+]).{8,}$';
        return (password.match(regex) !== null);
    }
}
