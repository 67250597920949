import { Injectable } from '@angular/core';

import * as io from 'socket.io-client';
import { Observable, Subject } from 'rxjs';

import { LoggingService } from '@services/logging.service';

import { environment } from '@environments/environment';

@Injectable()
export class SocketService {
    private socket;
    private socketUrl = environment.c_chatUrl;
    private readonly socketConfig = { forceNew: true, reconnectionAttempts: 5, secure: true };
    private reconnectionAttempts = 5; // number of times to try and establish a connection before giving up
    //
    public chatObservable: Observable<any> = null;

    constructor(
        private logger: LoggingService
    ) {}

    //  Initialize socket.io connection:
    initSocket() {
        this.socket = (this.socket) ? this.socket : this.socket = io(this.socketUrl, this.socketConfig);
    }

    //  Connect to Chat:
    public connectToChat(): void {
        this.initSocket();
        //
        let attempt = 1;
        this.socket.on('connect_error',
            (error) => {
                this.logger.print('Could not log into Chat: ', error, 'info');
                if (attempt <= this.reconnectionAttempts) {
                    attempt++;
                } else {
                    this.socket.disconnect();
                }
            }
        );
    }

    //  Log into Chat:
    public onLogIntoChat(payload: object) {
        this.socket.emit('login', payload);
        this.logger.print('Logged into Chat', '', 'info');
    }
    //  Log out of Chat:
    public onLogOutOfChat() {
        this.socket.disconnect();
        this.chatObservable = null;
        this.logger.print('Logged out of Chat', '', 'info');
    }


    //  Receive Message (emitted event triggered):
    public onReceiveMessage(): Observable<any> {
        this.chatObservable = new Observable(observer => {
            this.socket.on('message-received', (response) => {
                this.logger.print('New Chat Message received', response, 'info');
                observer.next(response);
            });
            return () => {
                this.socket.disconnect();
            };
        });
        // return observable;
        return this.chatObservable;
    }
    //  Send Message (emit event):
    public onSubmitMessage(payload: object) {
        this.logger.print('New Chat Message sent', payload, 'info');
        this.socket.emit('message-sent', payload);
    }


    //  Receive Message Read (emitted event triggered):
    public onReceiveMessageIsRead(): Observable<any> {
        this.initSocket();
        //  Create Subject for incoming 'Message read' stream:
        let chatMessageReadSubject = new Subject;
        this.socket.on('message-read-received', (data) => {
            this.logger.print('Chat Message Read received', data, 'info');
            chatMessageReadSubject.next(data);
        });
        return chatMessageReadSubject;
    }
    //  Send Message Read (emit event):
    public onSubmitMessageIsRead(payload: object) {
        this.logger.print('Chat Message Read sent', payload, 'info');
        this.socket.emit('message-read-sent', payload);
    }


    //  Block Conversation (emit event):
    public onBlockConversation(conversationId: number) {
        this.logger.print('Emitted \'Block Conversation\' event to Chat Server for Conversation with ID ', conversationId, 'info');
        this.socket.emit('block', conversationId);
    }
    //  Unblock Conversation (emit event):
    public onUnblockConversation(conversationId: number) {
        this.logger.print('Emitted \'Unblock Conversation\' event to Chat Server for Conversation with ID ', conversationId, 'info');
        this.socket.emit('unblock', conversationId);
    }
}
