import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { CoreService} from '../core.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-password-request',
    templateUrl: 'password-request.component.html'
})

export class PasswordRequestComponent implements OnInit {
    email: string;
    //
    isSubmitting = false;
    hasSubmitted = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;
    //
    public readonly hssRegisterUrl = environment.c_hssRegisterUrl;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private coreService: CoreService,
        private logger: LoggingService,
        public translate: TranslateService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {}

    onSubmitRequestPassword(): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        event.preventDefault();
        //  Per-field Input Validation:
        if (!this.email) {
            field = this.translate.instant('STRING.EMAIL');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitRequestPassword();
        }
    }

    doSubmitRequestPassword(): void {
        let postData = {
            email: this.email
        };
        //  Request:
        this.isSubmitting = true;
        let passwordRequestObservable = this.coreService.requestPassword(postData);
        passwordRequestObservable
            .pipe(finalize(() =>
                setTimeout(() => {
                    this.isSubmitting = false;
                }, 500)
            ))
            .subscribe(
                (response) => {
                    this.logger.print('Submit PasswordRequest succeeded', response, 'log');
                    this.feedbackClass = 'success';
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.REQUEST.SUBMIT.SUCCESS') + '</span>';
                    this.feedbackVisible = true;
                    //  Disable 'Submit'-button after successful submission:
                    this.hasSubmitted = true;
                },
                (error) => {
                    this.logger.print('Submit PasswordRequest failed', error, 'error');
                    if (error.status) {
                        if (error.error.message.toLowerCase() === 'password requested within the last 2 hours') {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.REQUEST.SUBMIT.ERROR.TIMEOUT') + '</span>';
                        } else  if (error.error.message.toLowerCase() === 'external user') {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.REQUEST.SUBMIT.ERROR.EXTERNAL.USER') + '</span>';
                        } else {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.REQUEST.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                        }
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.REQUEST.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                    //  Enable 'Submit'-button after failed submission:
                    this.hasSubmitted = false;
                },
                () => {}
            );
    }

    navigate(route): void {
        this.router.navigate([route]);
    }
}
