import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '@services/authentication.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-email-validate-request',
    templateUrl: 'email-validate.component.html'
})

export class EmailValidateComponent implements OnInit {
    emailValidationToken = null; // URL parameter
    isLoading = true;
    buttonVisible = false;
    //
    tokenInvalidVisible = false;
    tokenErrorVisible = false;
    tokenSuccessVisible = false;
    //
    public readonly hssRegisterUrl = environment.c_hssRegisterUrl;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private logger: LoggingService,
        public translate: TranslateService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Get EmailValidation Token from url:
        this.activatedRoute.queryParams.subscribe(params => {
            this.emailValidationToken = params['activationToken'];
        });
        //  Validate EmailValidation Token:
        this.onSubmitEmailValidationToken();
    }

    //  Check if all requirements to validate EmailValidation Token are met:
    onSubmitEmailValidationToken(): void {
        if (this.emailValidationToken === null) {
            //  Token not set => show feedback:
            setTimeout( () => {
                this.isLoading = false;
                this.tokenInvalidVisible = true;
                this.buttonVisible = true;
            }, 750);
        } else {
            //  Token set => Submit EmailValidation Token:
            this.doSubmitEmailValidationToken();
        }
    }

    //  Submit EmailValidation Token:
    doSubmitEmailValidationToken(): void {
        let emailValidationObservable = this.authenticationService.validateEmail(this.emailValidationToken);
        emailValidationObservable.subscribe(
            (response) => {
                this.logger.print('Validate Email with Token succeeded', response, 'log');
                this.tokenSuccessVisible = true;
                //  Update view:
                setTimeout(() => {
                    this.isLoading = false;
                    this.tokenErrorVisible = false;
                    this.tokenSuccessVisible = true;
                    this.buttonVisible = true;
                }, 500);
            },
            (error) => {
                this.logger.print('Validate Email with Token failed', error, 'error');
                //  Update view:
                setTimeout(() => {
                    this.isLoading = false;
                    this.tokenErrorVisible = true;
                    this.tokenSuccessVisible = false;
                    this.buttonVisible = false;
                }, 500);
            },
            () => {}
        );
    }

    navigate(route): void {
        this.router.navigate([route]);
    }
}
