export class Incident {
    id: number;
    index: number;
    alarmDate: string;
    city: string;
    fullAddress: string;
    hash: string;
    incidentRole: string;
    incidentUserId: number;

    constructor(incidentObj) {
        this.id = incidentObj.id;
        this.alarmDate = incidentObj.alarmDate;
        this.city = incidentObj.city;
        this.fullAddress = incidentObj.fullAddress;
        this.hash = incidentObj.hash;
        if (incidentObj.userIncident) {
            this.incidentRole = incidentObj.userIncident.incidentRole;
            this.incidentUserId = incidentObj.userIncident.id;
        } else {
            this.incidentRole = null;
            this.incidentUserId = null;
        }
    }
}
