import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse } from '@angular/common/http';
import { HttpClient, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';

import { throwError, Observable ,  BehaviorSubject } from 'rxjs';
import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';

import { AuthenticationService } from '@services/authentication.service';
import { LoggingService } from '@services/logging.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private logger: LoggingService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
        return next.handle(request)
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse && err.status === 401) {
                        //  Allow '401 - Bad credentials' response to be thrown as an error,
                        //  so we can display a proper message when invalid credentials are submitted:
                        if (err.error.message && err.error.message.toLowerCase() === 'bad credentials') {
                            return throwError(err);
                        } else {
                            return this.handle401Error(request, next);
                        }
                    } else {
                        return throwError(err);
                    }
                }));
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        //  Check if we are already refreshing token:

        // if (request.url.indexOf('/users/login') > -1) {
        //     return;
        // }

        if (!this.isRefreshingToken) {
            //  AccessToken is being refreshed:
            this.isRefreshingToken = true;
            //  Reset here so that the following requests wait until the updated accessToken is returned by refreshToken call:
            this.tokenSubject.next(null);
            return this.authenticationService.refreshToken(this.authenticationService.getRefreshToken())
                .pipe(
                    switchMap((response: any) => {
                        if (response) {
                            //  Response received: update Auth Object in LocalStorage and retry original request:
                            this.logger.print('Token refresh succeeded', response, 'log');
                            //  Store updated Auth Object in LocalStorage:
                            this.authenticationService.setAuthObject(response);
                            //  Retry original request:
                            return next.handle(this.addTokenToRequest(request, this.authenticationService.getAccessToken()));
                        }
                        //  No response => log out user:
                        this.logger.print('Token refresh failed, logging out', '', 'log');
                        return <any>this.authenticationService.logout();
                    }),
                    catchError(err => {
                        //  Refresh caused error => log out user:
                        this.logger.print('Token refresh failed, logging out', '', 'log');
                        return <any>this.authenticationService.logout();
                    }),
                    finalize(() => {
                        this.isRefreshingToken = false;
                    })
                );
        } else {
            //  AccessToken is NOT being refreshed:
            this.isRefreshingToken = false;
            return this.tokenSubject
                .pipe(filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        return next.handle(this.addTokenToRequest(request, token));
                    }));
        }
    }
}
