import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';
import { IncidentService } from '../incident.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService} from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import { Incident } from '../incident.model';

@Component({
    selector: 'hss-incident-list',
    templateUrl: 'incident-list.component.html'
})

export class IncidentListComponent implements OnInit {
    public currentUser: any = null;
    public isVolunteer: boolean;
    allIncidents: Incident[] = [];
    incidents: Incident[] = [];
    maxItemsPerPage = 20;
    currentPage = 0;
    showLoadMore = false;
    //
    isTableView: boolean;
    isMobile: boolean;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private deviceService: DeviceDetectorService,
        private incidentService: IncidentService,
        private logger: LoggingService,
        public translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.initUserData();
        //  Are we dealing with a mobile device (or a desktop with a resolution < 640p)?
        this.isMobile = this.deviceService.isMobile();
        if (!this.isMobile && window.innerWidth <= 640) {
            this.isMobile = true;
        }
        //  Set ViewMode (GridView for mobile, TableView for non-mobile):
        this.isTableView = true;
        //  Get Incidents:
        this.fetchIncidents();
    }

    initUserData(): void {
        this.currentUser = this.userService.getUser();
        this.isVolunteer = this.userService.isVolunteerOnly();
    }

    //  Fetch Incidents:
    fetchIncidents() {
        let self = this;
        //  Request:
        let incidentObservable = this.incidentService.getIncidents();
        incidentObservable
            .subscribe(
            (response) => {
                let incidentObjs = response as any;
                //  Incidents (all) found?
                if (incidentObjs.length === 0) {
                    //  No => Show feedback:
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENTS.NOT_FOUND') + '</span>';
                    this.feedbackClass = 'warning';
                    this.feedbackVisible = true;
                } else {
                    //  Yes => Store all Incidents in data-bound Array:
                    incidentObjs.forEach(function (incidentObj, $index) {
                        let incident = new Incident(incidentObj);
                        incident.index = $index;
                        self.allIncidents.push(incident);
                    });
                    if (this.allIncidents.length === 0) {
                        //  No => Show feedback:
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENTS.NOT_FOUND') + '</span>';
                        this.feedbackClass = 'warning';
                        this.feedbackVisible = true;
                    } else {
                        this.loadMoreIncidents();
                    }
                }
            },
            (error) => {
                this.logger.print('Fetch incidents failed', error, 'error');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENTS.ERROR.CODE', {error: error.status}) + '</span>';
                } else {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENTS.ERROR.UNKNOWN') + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
            },
            () => {}
        );
    }

    // Load the next 20 incidents
    loadMoreIncidents(): void {
        this.currentPage++;
        let maxItems = this.currentPage * this.maxItemsPerPage;
        let loopItems = this.allIncidents.length < maxItems ? this.allIncidents.length : maxItems;
        this.showLoadMore = loopItems < this.allIncidents.length;
        this.incidents =  this.allIncidents.slice(0, loopItems);
    }

    //  Toggle IncidentUser DisplayMode (TableView or GridView):
    setViewMode(isTableView): void {
        this.isTableView = isTableView;
    }

    //  Navigate:
    navigate(route: string): void {
        this.router.navigate(['/' + route]);
    }
}
