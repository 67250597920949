import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '@services/authentication.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-account-confirm',
    templateUrl: 'account-confirm.component.html'
})

export class AccountConfirmComponent implements OnInit {
    accountConfirmationToken = null; // URL parameter
    isLoading = true;
    buttonVisible = false;
    //
    tokenInvalidVisible = false;
    tokenErrorVisible = false;
    tokenSuccessVisible = false;
    //
    public readonly hssRegisterUrl = environment.c_hssRegisterUrl;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private logger: LoggingService,
        public translate: TranslateService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Get AccountConfirmation Token from url:
        this.activatedRoute.queryParams.subscribe(params => {
            this.accountConfirmationToken = params['activationToken'];
        });
        //  Validate AccountConfirmation Token:
        this.onSubmitAccountConfirmationToken();
    }

    //  Check if all requirements to validate AccountConfirmation Token are met:
    onSubmitAccountConfirmationToken(): void {
        if (this.accountConfirmationToken === null) {
            //  Token not set => show feedback:
            setTimeout( () => {
                this.isLoading = false;
                this.tokenInvalidVisible = true;
                this.buttonVisible = false;
            }, 750);
        } else {
            //  Token set => Submit AccountConfirmation Token:
            this.doSubmitAccountConfirmationToken();
        }
    }

    //  Submit AccountConfirmation Token:
    doSubmitAccountConfirmationToken(): void {
        let accountConfirmationObservable = this.authenticationService.confirmAccount(this.accountConfirmationToken);
        accountConfirmationObservable.subscribe(
            (response) => {
                this.logger.print('Confirm Account with Token succeeded', response, 'log');
                this.tokenSuccessVisible = true;
                //  Update view:
                setTimeout(() => {
                    this.isLoading = false;
                    this.tokenSuccessVisible = true;
                    this.buttonVisible = true;
                }, 500);
            },
            (error) => {
                this.logger.print('Confirm Account with Token failed', error, 'error');
                //  Update view:
                setTimeout(() => {
                    this.isLoading = false;
                    this.tokenErrorVisible = true;
                    this.buttonVisible = true;
                }, 500);
            },
            () => {}
        );
    }

    navigate(route): void {
        this.router.navigate([route]);
    }
}
