import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';

import { IncidentListComponent } from './incident-list/incident-list.component';
import { IncidentListItemComponent } from './incident-list-item/incident-list-item.component';
import { IncidentRegisterWithCodeComponent } from './incident-register-with-code/incident-register-with-code.component';
import { IncidentRegisterWithoutCodeComponent } from './incident-register-without-code/incident-register-without-code.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MyDatePickerModule } from 'mydatepicker';
import { NgSelectModule } from '@ng-select/ng-select';

import { IncidentService } from './incident.service';

import { throwIfAlreadyLoaded } from '@guards/module-import.guard';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/' , '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MyDatePickerModule,
        NgSelectModule
    ],
    exports: [
        IncidentListComponent,
        IncidentListItemComponent,
        IncidentRegisterWithCodeComponent,
        IncidentRegisterWithoutCodeComponent
    ],
    declarations: [
        IncidentListComponent,
        IncidentListItemComponent,
        IncidentRegisterWithCodeComponent,
        IncidentRegisterWithoutCodeComponent
    ],
    providers: [
        IncidentService
    ],
})

export class IncidentModule {
    constructor( @Optional() @SkipSelf() parentModule: IncidentModule) {
        throwIfAlreadyLoaded(parentModule, 'PublicModule');
    }
}
