import { Component } from '@angular/core';
import { OnInit } from '@angular/core';

import { finalize } from 'rxjs/operators';

import { ApiService } from '@services/api.service';
import { CoreService } from '../core.service';
import { LoggingService} from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

@Component({
    selector: 'hss-contact-authenticated',
    templateUrl: 'contact-authenticated.component.html'
})

export class ContactAuthenticatedComponent implements OnInit {
    currentUser: any;
    subject: string;
    message: string;
    //
    isSubmitting = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private apiService: ApiService,
        private coreService: CoreService,
        private logger: LoggingService,
        public translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
    }

    onSubmitContact(): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        event.preventDefault();
        //  Per-field Input Validation:
        if (!this.subject) {
            field = this.translate.instant('STRING.SUBJECT');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.message) {
            field = this.translate.instant('STRING.MESSAGE');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', { 'field': field }) + '</span>';
            this.feedbackVisible = true;
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitContact();
        }
    }

    doSubmitContact(): void {
        let postData = {
            subject: this.subject,
            message: this.message
        };
        //  Request:
        this.isSubmitting = true;
        let contactObservable = this.coreService.submitContactAuthenticated(postData);
        contactObservable
            .pipe(finalize(() =>
                setTimeout(() => {
                    this.isSubmitting = false;
                }, 500)
            ))
            .subscribe(
                (response) => {
                    this.logger.print('Submit Contact succeeded', response, 'log');
                    this.feedbackClass = 'success';
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.SUBMIT.SUCCESS') + '</span>';
                    this.feedbackVisible = true;
                    //  Clear form:
                    this.subject = '';
                    this.message = '';
                },
                (error) => {
                    this.logger.print('Submit Contact failed', error, 'error');
                    if (error.status) {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                },
                () => {}
            );
    }
}
