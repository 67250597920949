import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { AuthenticationService } from '@services/authentication.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import { User } from '@models/user.model';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-login',
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
    currentUser: User;
    email: string;
    password: string;
    //
    returnUrl: string; // URL parameter
    //
    isSubmitting = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;
    //
    public readonly hssRegisterUrl = environment.c_hssRegisterUrl;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private logger: LoggingService,
        public translate: TranslateService,
        private userService: UserService
    ) {
        //  Check for NavigationExtras: in this case used to indicate the user was redirected from Login-with-token because of a invalid/missing token:
        const navigation = this.router.getCurrentNavigation();
        if (navigation.extras) {
            const state = navigation.extras.state as { hsnTokenInvalid: boolean };
            //  Extra check to see if 'hsnTokenInvalid' is set in NavigationExtras:
            if (state) {
                //  'hsnTokenInvalid' is set => Show feedback to user:
                let feedback = '<span>' + this.translate.instant('STRING.LOGIN.TOKEN.ERROR') + '<br>' + this.translate.instant('STRING.LOGIN.BELOW') + '</span>';
                this.feedbackClass = 'error';
                this.feedbackMessage = feedback;
                this.feedbackVisible = true;
            }
        }
        //  Check for QueryParams: in this case the url of the page where User came from when he was logged out:
        this.activatedRoute.queryParams.subscribe(params => {
            this.returnUrl = params['returnUrl'];
        });
    }

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Log in if session is still active:
        this.autoLogin();
    }

    onSubmitLogin(event: any): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        event.preventDefault();
        //  Per-field Input Validation:
        if (!this.email) {
            field = this.translate.instant('STRING.EMAIL');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.password) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitLogin();
        }
    }

    doSubmitLogin(): void {
        //  Requests:
        //  STEP 1: CHECK LOGIN CREDENTIALS:
        this.isSubmitting = true;
        let loginObservable = this.authenticationService.login(this.email, this.password);
        loginObservable
            .pipe(finalize(() =>
                setTimeout(() => {
                    this.isSubmitting = false;
                }, 500)
            ))
            .subscribe(
                (response) => {
                    this.logger.print('Login succeeded', response, 'log');
                    //  Store Auth Object in LocalStorage:
                    this.authenticationService.setAuthObject(response);
                    //  STEP 2: Get User data:
                    let userObservable = this.userService.getUserData();
                    userObservable.subscribe(
                        (userData) => {
                            this.logger.print('GetUserData succeeded', userData, 'log');
                            this.userService.setUser(userData);
                            //  Route User to Dashboard:
                            if (this.returnUrl) {
                                this.router.navigate([this.returnUrl]);
                            } else {
                                this.router.navigate(['/dashboard/incidents']);
                            }
                        },
                        (error) => {
                            let errorCode = error.status;
                            this.logger.print('GetUserData failed', error, 'error');
                            if (errorCode) {
                                this.feedbackMessage = '<span>' + this.translate.instant('STRING.LOGIN.ERROR.GETUSERDATA.CODE', {error: errorCode}) + '</span>';
                            } else {
                                this.feedbackMessage = '<span>' + this.translate.instant('STRING.LOGIN.ERROR.GETUSERDATA.UNKNOWN') + '</span>';
                            }
                            this.feedbackClass = 'error';
                            this.feedbackVisible = true;
                        },
                        () => {}
                    );
                },
                (error) => {
                    this.logger.print('Login failed', error, 'error');
                    let errorCode = error.error.code;
                    if (error.error.message) {
                        let errorMessage = error.error.message.toLowerCase();
                        if (errorCode === 401) {
                            switch (errorMessage) {
                                case 'bad credentials':
                                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.LOGIN.INVALID') + '</span>';
                                    break;
                                default:
                                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.LOGIN.ERROR.CODE', {error: errorCode}) + '</span>';
                                    break;
                            }
                        } else {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.LOGIN.ERROR.CODE', {error: errorCode}) + '</span>';
                        }
                    } else {
                        if (errorCode) {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.LOGIN.ERROR.CODE', {error: errorCode}) + '</span>';
                        } else {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.LOGIN.ERROR.UNKNOWN') + '</span>';
                        }
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                },
                () => {}
            );
    }

    autoLogin(): void {
        let self = this;
        //  User in localStorage?
        if (localStorage.getItem('currentUser')) {
            //  User in LocalStorage => authenticate user:
            let userObservable = this.userService.getUserData();
            userObservable.subscribe(
                (response) => {
                    //  User authenticated => proceed to Dashboard:
                    this.logger.print('User authenticated, proceed to Dashboard', response, 'log');
                    //  Redirect:
                    this.router.navigate(['/dashboard/incidents']);
                },
                (error) => {
                    //  User not authenticated => log out:
                    this.logger.print('User cannot be authenticated, log out current User', error, 'error');
                    self.authenticationService.logout();
                },
                () => {});
        } else {}
    }

    navigate(route): void {
        this.router.navigate([route]);
    }
}
