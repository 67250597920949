import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';

import { AccountConfirmComponent } from './account-confirm/account-confirm.component';
import { ContactComponent } from './contact/contact.component';
import { EmailValidateComponent } from './email-validate/email-validate.component';
import { LoginComponent } from './login/login.component';
import { LoginWithTokenComponent } from './login-with-token/login-with-token.component';
import { PasswordRequestComponent } from './password-request/password-request.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RegisterComponent } from './register/register.component';
import { RequestConfirmComponent } from './request-confirm/request-confirm.component';

import { ProfileComponent } from './profile/profile.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { AboutComponent } from './about/about.component';
import { ContactAuthenticatedComponent } from './contact-authenticated/contact-authenticated.component';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { ngfModule } from 'angular-file';
import { NgSelectModule } from '@ng-select/ng-select';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { CoreService } from './core.service';

import { throwIfAlreadyLoaded } from '@guards/module-import.guard';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/' , '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ngfModule,
        NgSelectModule,
        PasswordStrengthBarModule,
        SweetAlert2Module.forRoot({ // Provide default SweetAlert2-native options (in this case, Bootstrap)
            customClass: 'swal-content',
            cancelButtonClass: 'button secondary',
            confirmButtonClass: 'button primary',
            buttonsStyling: false
        }),
    ],
    exports: [
        //  Public:
        AccountConfirmComponent,
        ContactComponent,
        EmailValidateComponent,
        LoginComponent,
        LoginWithTokenComponent,
        PasswordRequestComponent,
        PasswordResetComponent,
        RegisterComponent,
        RequestConfirmComponent,
        //  Authenticated:
        ProfileComponent,
        PasswordChangeComponent,
        AboutComponent,
        ContactAuthenticatedComponent
    ],
    declarations: [
        //  Public:
        AccountConfirmComponent,
        ContactComponent,
        EmailValidateComponent,
        LoginComponent,
        LoginWithTokenComponent,
        PasswordRequestComponent,
        PasswordResetComponent,
        RegisterComponent,
        RequestConfirmComponent,
        //  Autheticated:
        ProfileComponent,
        PasswordChangeComponent,
        AboutComponent,
        ContactAuthenticatedComponent
    ],
    providers: [
        CoreService
    ],
})

export class CoreModule {
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'PublicModule');
    }
}
