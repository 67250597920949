import { Injectable } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import * as moment from 'moment';

@Injectable()
export class DateService {

    constructor(
        private translate: TranslateService
    ) {}

    toUTCLongDate(date: any): any {
        return moment.utc(date);
    }

    toUTCLongDateFormatted(date: any): any {
        return moment.utc(date).format('LLL');
    }

    toUTCShortDate(date: any, delimiter: string = '-'): any {
        return moment.utc(date).format('DD' + delimiter + 'MM' + delimiter + 'YYYY');
    }

    toUTCShortDateFormatted(date: any): any {
        return moment.utc(date).format('LL');
    }

    toLocalLongDate(date: any): any {
        return moment.utc(date).local();
    }

    toLocalLongDateFormatted(date: any): any {
        return moment.utc(date).local().format('LLL');
    }

    toLocalShortDate(date: any, delimiter: string = '-'): any {
        return moment.utc(date).local().format('DD' + delimiter + 'MM' + delimiter + 'YYYY');
    }

    toLocalShortUSDate(date: any, delimiter: string = '-'): any {
        return moment.utc(date).local().format('YYYY' + delimiter + 'MM' + delimiter + 'DD');
    }

    toLocalShortDateFormatted(date: any): any {
        return moment.utc(date).local().format('LL');
    }

    toLocalShortestDate(date: any): any {
        return moment.utc(date).local().format('DD-MM');
    }

    toLocalShortDayName(date: any): any {
        return moment.utc(date).local().format('dd');
    }

    toLocalMediumDayName(date: any): any {
        return moment.utc(date).local().format('ddd');
    }

    toLocalFullDayName(date: any): any {
        return moment.utc(date).local().format('dddd');
    }

    toLocalTime(date: any): any {
        let momentDate = moment.utc(date).local();
        let momentHours = (momentDate.get('hours') < 10) ? '0' + momentDate.get('hours') : momentDate.get('hours');
        let momentMinutes = (momentDate.get('minutes') < 10) ? '0' + momentDate.get('minutes') : momentDate.get('minutes');
        return momentHours + ':' + momentMinutes;
    }

    hoursAgo(date: any) {
        let momentDate = moment(date, 'YYYY-MM-DD').local();
        return moment().diff(momentDate, 'hours');
    }

    daysAgo(date: any) {
        let momentDate = moment(date, 'YYYY-MM-DD').local();
        return moment().diff(momentDate, 'days');
    }

    sameDay(date: any, prevDate: any) {
        let momentDate = moment(date, 'YYYY-MM-DD').local();
        let momentPrevDate = moment(prevDate, 'YYYY-MM-DD').local();
        return momentDate.isSame(momentPrevDate, 'day');
    }

    getDatePickerNowObj() {
        let momentDate = moment.utc().local();
        return { date: { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() } };
    }

    getTimeAgo(date: any) {
        let timeAgo = moment.utc(date).local().fromNow();
        return this.translate.instant('STRING.LAST.SEEN', { 'time': timeAgo });
    }

    getJsDateObj(date?: any): any {
        return date != null ? date.getTime() : 0;
    }
}
