import { Injectable } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import {ApiService} from '@services/api.service';

@Injectable()
export class ChatService {
    @Output() incomingMessage: EventEmitter<any> = new EventEmitter();
    activeConversationId: number = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private apiService: ApiService) {
    }

    //  Get all Chat Conversations:
    getConversations(): Observable<Response> {
        //  Request options:
        let endpoint = '/conversations';
        let type = 'GET';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Get all Messages of Conversation with ID 'conversationId':
    getConversationMessages(conversationId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/conversations/' + conversationId + '/messages';
        let type = 'GET';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Emit incoming Message to Component:
    emitIncomingMessage(messageObj: any) {
        this.incomingMessage.emit(messageObj);
    }

    //  Mark as read Conversation with ID 'conversationId':
    markConversationAsRead(conversationId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/conversations/' + conversationId + '/read';
        let type = 'PUT';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  (Un)Block Conversation with ID 'conversationId':
    blockConversationById(conversationId: number, blockConversation: boolean): Observable<Response> {
        //  Request options:
        let endpoint = '/conversations/' + conversationId + '/block?state=' + blockConversation;
        let type = 'PUT';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Delete Conversation with ID 'conversationId':
    deleteConversationById(conversationId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/conversations/' + conversationId;
        let type = 'DELETE';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Post Message to ConversationConversation with ID 'conversationId':
    postMessage(content: string, conversationId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/conversations/' + conversationId + '/messages';
        let type = 'POST';
        let postData = {
            content: content
        };
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }

    //  Search Conversations for search query 'searchQuery':
    searchMessages(searchQuery: string): Observable<Response> {
        //  Request options:
        let endpoint = '/conversations/search?query=' + searchQuery;
        let type = 'GET';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }
}
