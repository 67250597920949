import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountConfirmComponent } from '@module.core/account-confirm/account-confirm.component';
import { ContactComponent } from '@module.core/contact/contact.component';
import { EmailValidateComponent } from '@module.core/email-validate/email-validate.component';
import { LoginComponent } from '@module.core/login/login.component';
import { LoginWithTokenComponent } from '@module.core/login-with-token/login-with-token.component';
import { PasswordRequestComponent } from '@module.core/password-request/password-request.component';
import { PasswordResetComponent } from '@module.core/password-reset/password-reset.component';
import { RegisterComponent } from '@module.core/register/register.component';
import { RequestConfirmComponent } from '@module.core/request-confirm/request-confirm.component';

import { ProfileComponent } from '@module.core/profile/profile.component';
import { PasswordChangeComponent } from '@module.core/password-change/password-change.component';
import { AboutComponent } from '@module.core/about/about.component';
import { ContactAuthenticatedComponent } from '@module.core/contact-authenticated/contact-authenticated.component';

import { DashboardCoreComponent } from '@module.dashboard/dashboard-core/dashboard-core.component';
import { ChatCoreComponent } from '@module.chat/chat-core/chat-core.component';
import { IncidentListComponent } from '@module.incident/incident-list/incident-list.component';
import { IncidentRegisterWithCodeComponent } from '@module.incident/incident-register-with-code/incident-register-with-code.component';
import { IncidentRegisterWithoutCodeComponent } from '@module.incident/incident-register-without-code/incident-register-without-code.component';

import { PluginsComponent } from '@module.playground/plugins/plugins.component';
import { StyleGuideComponent } from '@module.playground/styleguide/styleguide.component';

import { AuthenticationGuard } from '@guards/authentication.guard';

const routes: Routes = [
    {
        path: 'activate',
        component: AccountConfirmComponent,
        data: { title: 'PAGETITLE.ACCOUNT.CONFIRM' }
    },
    {
        path: 'activate/:id',
        component: AccountConfirmComponent,
        data: { title: 'PAGETITLE.ACCOUNT.CONFIRM' }
    },
    {
        path: 'contact',
        component: ContactComponent,
        data: { title: 'PAGETITLE.CONTACT' }
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { title: 'PAGETITLE.LOGIN' }
    },
    {
        path: 'login-with-token',
        component: LoginWithTokenComponent,
        data: { title: 'PAGETITLE.LOGIN' }
    },
    {
        path: 'login-with-token/:id',
        component: LoginWithTokenComponent,
        data: { title: 'PAGETITLE.LOGIN' }
    },
    {
        path: 'email-validate',
        component: EmailValidateComponent,
        data: { title: 'PAGETITLE.EMAIL.VALIDATE' }
    },
    {
        path: 'email-validate/:id',
        component: EmailValidateComponent,
        data: { title: 'PAGETITLE.EMAIL.VALIDATE' }
    },
    {
        path: 'password-request',
        component: PasswordRequestComponent,
        data: { title: 'PAGETITLE.PASSWORD.REQUEST' }
    },
    {
        path: 'password-reset',
        component: PasswordResetComponent,
        data: { title: 'PAGETITLE.PASSWORD.RESET' }
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: { title: 'PAGETITLE.REGISTER' }
    },
    {
        path: 'confirm-request',
        component: RequestConfirmComponent,
        data: { title: 'PAGETITLE.REQUEST.CONFIRM' }
    },
    {
        path: 'confirm-request/:id',
        component: RequestConfirmComponent,
        data: { title: 'PAGETITLE.REQUEST.CONFIRM' }
    },
    {
        path: '',
        redirectTo: '/dashboard/incidents',
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.INCIDENTS' }
    },
    {
        path: 'dashboard',
        redirectTo: '/dashboard/incidents',
        pathMatch: 'full',
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.INCIDENTS' }
    },
    {
        path: 'dashboard/incidents',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.INCIDENTS' },
        children: [
            {
                path: '',
                component: IncidentListComponent,
                data: {animation: 'fadeAnimation'}
            }
        ]
    },
    {
        path: 'dashboard/incidents/:id',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.INCIDENTS' },
        children: [
            {
                path: '',
                component: IncidentListComponent,
                data: {animation: 'fadeAnimation'}
            }
        ]
    },
    {
        path: 'dashboard/register-incident-with-code',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.INCIDENT.REGISTER.WITH_CODE' },
        children: [
            {
                path: '',
                component: IncidentRegisterWithCodeComponent,
                data: {animation: 'fadeAnimation'}
            }
        ]
    },
    {
        path: 'dashboard/register-incident-without-code',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.INCIDENT.REGISTER.WITHOUT_CODE' },
        children: [
            {
                path: '',
                component: IncidentRegisterWithoutCodeComponent,
                data: {animation: 'fadeAnimation'}
            }
        ]
    },
    {
        path: 'dashboard/profile',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.PROFILE' },
        children: [
            {
                path: '',
                component: ProfileComponent,
                data: {animation: 'fadeAnimation'}
            }
        ]
    },
    {
        path: 'dashboard/change-password',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.PASSWORD.CHANGE' },
        children: [
            {
                path: '',
                component: PasswordChangeComponent,
                data: {animation: 'fadeAnimation'}
            }
        ]
    },
    {
        path: 'dashboard/inbox',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.INBOX' },
        children: [
            {
                path: '',
                component: ChatCoreComponent,
                data: {animation: 'fadeAnimation'}
            }
        ]
    },
    {
        path: 'dashboard/inbox/:id',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.INBOX' },
        children: [
            {
                path: '',
                component: ChatCoreComponent,
                data: {animation: 'fadeAnimation'}
            }
        ]
    },
    {
        path: 'dashboard/about',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.ABOUT' },
        children: [
            {
                path: '',
                component: AboutComponent,
                data: {animation: 'slideToRight'}
            }
        ]
    },
    {
        path: 'dashboard/contact',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.CONTACT' },
        children: [
            {
                path: '',
                component: ContactAuthenticatedComponent,
                data: {animation: 'slideToRight'}
            }
        ]
    },
    {
        path: 'dashboard/plugins',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.PLUGINS' },
        children: [
            {
                path: '',
                component: PluginsComponent,
                data: {animation: 'slideToRight'}
            }
        ]
    },
    {
        path: 'dashboard/styleguide',
        component: DashboardCoreComponent,
        canActivate: [AuthenticationGuard],
        data: { title: 'PAGETITLE.SANDBOX' },
        children: [
            {
                path: '',
                component: StyleGuideComponent,
                data: {animation: 'slideToRight'}
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/login'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        useHash: true
    })],
    exports: [RouterModule]
})

export class AppRoutingModule {}
