import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ApiService } from '@services/api.service';
import { LoggingService } from '@services/logging.service';

@Injectable()
export class AuthenticationService {
    public token: string;
    public currentUser: any;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private logger: LoggingService
    ) {}

    //  Log in with credentials:
    login(email: string, password: string): Observable<Response> {
        //  Request options:
        let endpoint = '/users/login';
        let type = 'POST';
        let postData = {
            email: email,
            password: password
        };
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Log in with HSN Token:
    loginWithToken(token: string): Observable<Response> {
        //  Request options:
        let endpoint = '/users/login-with-token';
        let type = 'POST';
        let postData = {
            token: token
        };
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Refresh AccessToken with RefreshToken:
    refreshToken(refresh_token: string): Observable<Response> {
        //  Request options:
        let endpoint = '/token/refresh';
        let type = 'POST';
        let postData = {
            refresh_token: refresh_token
        };
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Log out CurrentUser:
    logout(): Observable<Response> {
        //  Request options:
        let endpoint = '/users/logout?refresh_token=' + this.getRefreshToken();
        let type = 'GET';
        let contentType = 'application/json';
        //  Request:
        return this.apiService.apiCall(endpoint, type, null, contentType);
    }

    //  Confirm Account:
    confirmAccount(token: string): Observable<Response> {
        //  Request options:
        let endpoint = '/users/activate';
        let type = 'POST';
        let postData = {
            activationToken: token
        };
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Confirm Request:
    confirmRequest(token: string): Observable<Response> {
        //  Request options:
        let endpoint = '/incident-token-requests/confirm';
        let type = 'POST';
        let postData = {
            confirmationToken: token
        };
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }

    //  Validate Email Address change:
    validateEmail( token: string): Observable<Response> {
        //  Request options:
        let endpoint = '/users/change-email-validation';
        let type = 'POST';
        let postData = {
            activationToken: token
        };
        let contentType = 'application/json';
        let secure = false;
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData, contentType, secure);
    }


    // ---  Get/Set methods:
    getAccessToken() {
        if (localStorage.getItem('auth')) {
            return JSON.parse(localStorage.getItem('auth')).token;
        } else {
            return null;
        }
    }

    getRefreshToken() {
        if (localStorage.getItem('auth')) {
            return JSON.parse(localStorage.getItem('auth')).refresh_token;
        } else {
            return null;
        }
    }

    // getAuthObject() {
    //     return JSON.parse(localStorage.getItem('auth'));
    // }

    setAuthObject(auth) {
        localStorage.setItem('auth', JSON.stringify(auth));
        this.logger.print('AuthObj stored in LocalStorage', auth, 'log');
    }

    clearAuthObj() {
        localStorage.removeItem('auth');
        localStorage.removeItem('currentUser');
        this.logger.print('AuthObj/CurrentUserObj removed from LocalStorage', '', 'log');
        return true;
    }
}
