import { Component } from '@angular/core';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { AuthenticationService } from '@services/authentication.service';
import { ApiService } from '@services/api.service';
import { LoggingService } from '@services/logging.service';
import { SocketService } from '@services/socket.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import swal from 'sweetalert2';

@Component({
    selector: 'hss-profile',
    templateUrl: 'profile.component.html'
})

export class ProfileComponent implements OnInit {
    currentUser: any;
    isVolunteer: boolean;
    incidentRoles: any = [];
    availableRoles: any;
    //  mugshot-related:
    accept = 'image/*';
    dragFiles: File[] = [];
    files: File[] = [];
    currentFileUpload: any;
    currentFileUploadPath: string;
    updatedFileUpload: any = null;
    maxUploadSize = 2097162; // 2MB in bytes
    validDrag: boolean;
    //
    isSubmittingProfile = false;
    isSubmittingAvatar = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private authenticationService: AuthenticationService,
        private logger: LoggingService,
        private socketService: SocketService,
        public translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
        this.isVolunteer = this.userService.isVolunteer();
        this.currentFileUpload = this.currentUser.image;
        //  Must use timeout for translations to apply (even if set to ridiculously small value like 25ms), just 'cause:
        setTimeout(() => {
            this.initializeRolesDropdown();
        }, 25);
    }

    initializeRolesDropdown(): void {
        //  Get all User IncidentRoles:
        this.incidentRoles = this.userService.getUserIncidentRoles(false);
        //  Get all available Incident Roles:
        this.availableRoles = this.userService.getIncidentRoles(false);
    }

    onSubmitProfile(): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        event.preventDefault();
        //  Per-field Input Validation:
        if (!this.currentUser.firstName) {
            field = this.translate.instant('STRING.NAME.FIRST');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.currentUser.lastName) {
            field = this.translate.instant('STRING.NAME.LAST');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.currentUser.email) {
            field = this.translate.instant('STRING.EMAIL');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.isVolunteer && this.incidentRoles.length === 0) {
            //  Non-Volunteers need to select at least 1 role from the dropdown:
            field = this.translate.instant('STRING.RESUSCITATION.ROLES');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', { 'field': field }) + '</span>';
            this.feedbackVisible = true;
        } else {
            //  Roles are set => cast local IncidentRoles to CurrentUser's IncidentRoles:
            this.currentUser.incidentRoles = this.incidentRoles;
            if (this.isVolunteer) {
                this.currentUser.incidentRoles.push('volunteer');
            }
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitProfile();
        }
    }

    doSubmitProfile(): void {
        //  Save updated avatar, if set:
        if (this.updatedFileUpload) {
            console.log('UPLOAD AVATAR');
            this.doUploadAvatar();
        } else {
            console.log('DO NOT UPLOAD AVATAR');
        }
        //  Save profile data:
        let postData = {
            firstName: this.currentUser.firstName,
            lastName: this.currentUser.lastName,
            email: this.currentUser.email,
            requestedEmail: this.currentUser.email,
            incidentRoles: this.currentUser.incidentRoles
        };
        //  Request:
        this.isSubmittingProfile = true;
        let userObservable = this.userService.updateUserData(postData, this.currentUser.id);
        userObservable
            .pipe(finalize(() =>
                setTimeout( () => {
                    this.isSubmittingProfile = false;
                }, 500)
            ))
            .subscribe(
            (response) => {
                this.logger.print('Update User succeeded: ', response, 'log');
                this.feedbackClass = 'success';
                this.feedbackMessage = '<span>' + this.translate.instant('STRING.PROFILE.EDIT.SUBMIT.SUCCESS') + '</span>';
                this.feedbackVisible = true;
                //  Update User fullName and store updated UserObj:
                this.currentUser.fullName = this.currentUser.firstName + ' ' + this.currentUser.lastName;
                this.userService.setUser(this.currentUser);
                //  Update IsVolunteer status (so changes in IncidentRoles is reflected in enabled/disabled input fields and IsVolunteer checkbox status):
                this.isVolunteer = this.currentUser.incidentRoles.includes('volunteer');
            },
            (error) => {
                this.logger.print('Update User failed: ', error, 'error');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.PROFILE.EDIT.SUBMIT.ERROR.CODE', { error: error.status}) + '</span>';
                } else {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.PROFILE.EDIT.SUBMIT.ERROR.UNKNOWN') + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
            },
            () => {}
        );
    }

    doDeleteProfile(): void {
        //  Request:
        let userObservable = this.userService.deleteUserData(this.currentUser.id);
        userObservable
            .subscribe(
                (response) => {
                    this.logger.print('Delete User succeeded: ', response, 'log');
                    swal.fire({
                        customClass: 'swal-content',
                        title: this.translate.instant('STRING.ACCOUNT.DELETED'),
                        html: '<p>' + this.translate.instant('STRING.ACCOUNT.DELETED.TEXT') + '</p>',
                        type: 'question',
                        showCancelButton: false,
                        confirmButtonText: this.translate.instant('STRING.ACCOUNT.DELETED.LOGOUT'),
                        confirmButtonClass: 'button primary',
                        buttonsStyling: false
                    }).then(() => {
                        //  Log out CurrentUser:
                        this.authenticationService.clearAuthObj();
                        this.socketService.onLogOutOfChat();
                        this.router.navigate(['/login']);
                    });
                },
                (error) => {
                    this.logger.print('Delete User failed: ', error, 'error');
                    if (error.status) {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.ACCOUNT.DELETE.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.ACCOUNT.DELETE.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                },
                () => {}
            );
    }

    doUploadAvatar(): void {
        let postData = new FormData();
        let avatar = this.updatedFileUpload[this.updatedFileUpload.length - 1];
        postData.append('file', avatar, avatar.name);
        //  Request:
        this.isSubmittingAvatar = true;
        let userObservable = this.userService.updateUserAvatar(postData);
        userObservable
            .pipe(finalize(() =>
                setTimeout(() => {
                    this.isSubmittingAvatar = false;
                }, 500)
            ))
            .subscribe(
                (response) => {
                    this.logger.print('Update Avatar succeeded: ', response, 'log');
                    this.feedbackClass = 'success';
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.AVATAR.UPLOAD.SUBMIT.SUCCESS') + '</span>';
                    this.feedbackVisible = true;
                    //  Update User Avatar and store updated UserObj:
                    this.currentUser.image = response['image'];
                    this.currentUser.imageBase64 = response['base64'];
                    this.userService.setUser(this.currentUser);
                    this.currentFileUpload = this.currentUser.image;
                    //  Reset values:
                    this.updatedFileUpload = null;
                    //  Clear upload-form:
                    this.files = [];
                },
                (error) => {
                    this.logger.print('Upload Avatar failed: ', error, 'error');
                    if (error.status) {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.AVATAR.UPLOAD.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.AVATAR.UPLOAD.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                },
                () => {}
            );
    }

    onRemoveAvatar(): void {
        //  Add confirm?
        this.doRemoveAvatar();
    }

    doRemoveAvatar(): void {
        //  Request:
        let userObservable = this.userService.deleteUserAvatar();
        userObservable
            .subscribe(
                (response) => {
                    this.logger.print('Remove Avatar succeeded: ', response, 'log');
                    this.feedbackClass = 'success';
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.AVATAR.REMOVE.SUBMIT.SUCCESS') + '</span>';
                    this.feedbackVisible = true;
                    //  Update and store UserObj:
                    this.currentUser.image = null;
                    this.currentUser.imageBase64 = null;
                    this.userService.setUser(this.currentUser);
                    //  Reset values:
                    this.currentFileUpload = null;
                    this.updatedFileUpload = null;
                    //  Clear upload-form:
                    this.files = [];
                },
                (error) => {
                    this.logger.print('Remove Avatar failed: ', error, 'error');
                    if (error.status) {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.AVATAR.UPLOAD.REMOVE.ERROR.CODE', {error: error.status}) + '</span>';
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.AVATAR.REMOVE.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                },
                () => {}
            );
    }

    //  Angular-file related (file upload):
    onAvatarChanged(files): void {
        if (files.length === 0) {
            return;
        }
        let reader = new FileReader();
        this.currentFileUploadPath = files;
        reader.readAsDataURL(files[files.length - 1]);
        reader.onload = () => {
            this.currentFileUpload = reader.result;
            this.updatedFileUpload = files;
        };
    }

    onRemoveSelectedAvatar($event): void {
        $event.stopPropagation();
        //
        this.files = [];
        this.currentFileUpload = this.currentUser.image;
    }

    getMugshotStyle(): object {
        return { 'background-image': 'url(' + this.currentFileUpload + ')' };
    }

    navigate(route: string): void {
        this.router.navigate(['/' + route]);
    }
}
