import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { filter, map, mergeMap } from 'rxjs/operators';

import { AuthenticationService } from '@services/authentication.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import { User } from '@models/user.model';

import * as moment from 'moment';

@Component({
    selector: 'hss-app',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
    public currentUser: User;
    public defaultLocale: any = 'nl';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private deviceService: DeviceDetectorService,
        private logger: LoggingService,
        private titleService: Title,
        private translate: TranslateService,
        private userService: UserService
    ) {
        //  Set css classes to body element:
        this.setCssClasses();
    }

    ngOnInit() {
        //  Set app language:
        this.setAppLanguage();
        //  Dynamically set localized page title:
        this.setPageTitle();
    }

    //  Set specific device info as css class(es) on body element (mobile/tablet-only):
    public setCssClasses() {
        //  Device-related:
        const html = document.getElementsByTagName('html')[0];
        let deviceInfo = this.deviceService.getDeviceInfo();
        let deviceOs = deviceInfo.os.toLowerCase();
        let deviceType;
        let deviceBrowser = deviceInfo.browser.toLowerCase();
        //
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            if (deviceOs === 'mac') {
                deviceOs = 'ios';
            } else if (deviceOs === 'windows-phone') {
                deviceOs = 'winmobile';
            }
            deviceType = (this.deviceService.isMobile()) ? 'mobile' : 'tablet';
        } else {
            deviceOs = deviceInfo.os.toLowerCase();
            deviceType = 'desktop';
        }
        html.classList.add(deviceType, deviceOs, deviceBrowser);
        //  LoginStatus-related:
        const body = document.getElementsByTagName('body')[0];
        let isLoggedIn = this.authenticationService.getAccessToken();
        if (isLoggedIn) {
            body.classList.add('logged_in');
        } else {
            body.classList.remove('logged_in');
        }
    }

    //  Set app language (if user is logged in use preferred language, otherwise fall back to default language [nl]):
    setAppLanguage(): void {
        this.translate.setDefaultLang(this.defaultLocale);
        moment.locale(this.defaultLocale);
        //  Check if user is set: if so, use preferredLanguage as app language:
        if (localStorage.getItem('currentUser') && localStorage.getItem('auth')) {
            this.logger.print('User logged in', '', 'log', false);
            if (!this.translate.currentLang) {
                this.logger.print('--== UserLanguage not set ==--', '', 'log');
                //
                this.currentUser = this.userService.getUser();
                if (this.currentUser) {
                    if (this.currentUser.preferredLanguage) {
                        this.logger.print('User and UserLanguage set to', this.currentUser.preferredLanguage, 'log');
                        this.translate.use(this.currentUser.preferredLanguage);
                        moment.locale(this.currentUser.preferredLanguage);
                    } else {
                        this.logger.print('User set, UserLanguage not set, use default language', this.defaultLocale, 'log');
                        this.translate.use(this.defaultLocale);
                    }
                } else {
                    this.logger.print('User not set, use default language', this.defaultLocale, 'log');
                    this.translate.use(this.defaultLocale);
                }
            } else {
                this.logger.print('--== Language set ==--', '', 'log');
            }
        } else {
            this.logger.print('Not logged in, use default language', this.defaultLocale, 'log');
            this.translate.use(this.defaultLocale);
        }
    }

    //  Set page title (set up Observable to set/update and translate page title on the fly):
    setPageTitle(): void {
        setTimeout(() => {
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data))
                .subscribe((event) => this.titleService.setTitle(
                    this.translate.instant('STRING.APP.NAME') + ' | ' + this.translate.instant(event['title'])
                ));
        }, 25);
    }
}
