export class User {
    id: number;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    image: string;
    imageBase64: string;
    preferredLanguage: string;
    roles: any;
    incidentRoles: any;
    //  IncidentUser properties:
    role: string;
    status: string;
    //  ChatUser properties:
    lastSeen: any;
    contactRequestCount: number;
    unreadMessages: number;

    constructor(userObj) {
        this.email = userObj.email;
        this.firstName = userObj.firstName;
        this.id = userObj.id;
        this.lastName = userObj.lastName;
        this.middleName = userObj.middleName;
        this.fullName = userObj.fullName;
        this.image = userObj.image;
        this.imageBase64 = userObj.imageBase64;
        this.preferredLanguage = 'nl'; // TMP
        //  UserRoles:
        this.roles = userObj.roles;
        //  IncidentRoles:
        this.incidentRoles = userObj.incidentRoles;
        //  IncidentUser properties:
        this.role = userObj.role;
        this.status = userObj.status;
        //  ChatUser properties:
        this.lastSeen = userObj.lastSeen;
        this.contactRequestCount = (userObj.contactRequestCount) ? userObj.contactRequestCount : 0;
        this.unreadMessages = (userObj.unreadMessages) ? userObj.unreadMessages : 0;
    }
}
