import { Component, ElementRef } from '@angular/core';
import { Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChange } from '@angular/core';

import { Observable } from 'rxjs';

import { ChatService } from '../chat.service';
import { DateService } from '@services/date.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoggingService } from '@services/logging.service';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { SocketService } from '@services/socket.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import { Conversation } from '../conversation.model';
import { Message } from '../message.model';

import { SwalPartialTargets } from '@sweetalert2/ngx-sweetalert2';
import swal from 'sweetalert2';

@Component({
    selector: 'hss-conversation-window',
    templateUrl: 'conversation-window.component.html'
})

export class ConversationWindowComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() selectedConversation: Conversation;
    @Output() selectedConversationDeleted = new EventEmitter<Conversation>();
    @Output() activeConversationUpdated = new EventEmitter<any>();
    @Output() inactiveConversationUpdated = new EventEmitter<any>();
    @ViewChild('chatReplyInput', {static: false}) chatReplyInput: ElementRef;
    private currentUser: any;
    private isBlocked: boolean;
    private isDeleted: boolean;
    messages = [];
    userMessage: string;
    lastSeen: any;
    dataLoading = false;
    incomingMessageObservable: Observable<any>; // Listener for incoming 'Message events
    //
    isMobile: boolean;
    mobileMenuVisible: boolean;
    conversationCssClass: string;
    scrollInertia: number;
    scrollToBottom = true;
    //
    private toastrOptions = {
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        timeOut: 5000
    } as any;
    private scrollbarOptions = {
        axis: 'y',
        theme: 'custom-theme',
        alwaysShowScrollbar: 1,
        mouseWheel: { enable: true },
        scrollButtons: { enable: false }
    } as any;
    private scrollbarOptionsAndroid = {
        axis: 'y',
        theme: 'custom-theme',
        alwaysShowScrollbar: 1,
        mouseWheel: { enable: false },
        scrollButtons: { enable: false }
    } as any;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;
    feedbackIsError = false;
    conversationVisible = false;

    constructor(
        private chatService: ChatService,
        private dateService: DateService,
        private deviceService: DeviceDetectorService,
        private logger: LoggingService,
        private mScrollbarService: MalihuScrollbarService,
        private socketService: SocketService,
        private toastr: ToastrService,
        public translate: TranslateService,
        private userService: UserService,
        public readonly swalTargets: SwalPartialTargets
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
        //  Are we dealing with a mobile device?
        this.isMobile = this.deviceService.isMobile();
        //  Init. helper CSS class:
        this.conversationCssClass = '';
        //  Hide UserMenu (mobile) onLoad:
        this.mobileMenuVisible = false;
        this.scrollInertia = (this.isMobile) ? 0 : 1000;
        //  Init. listener for incoming ConversationMessages (real-time):
        this.initMessageListener();
    }

    ngAfterViewInit() {
        if (this.isMobile && this.deviceService.os.toLowerCase() === 'android') {
            this.mScrollbarService.initScrollbar('#chat-conversation', this.scrollbarOptionsAndroid);
        } else {
            this.mScrollbarService.initScrollbar('#chat-conversation', this.scrollbarOptions);
        }
    }

    ngOnDestroy() {
        this.mScrollbarService.destroy('#chat-conversation');
    }

    ngOnChanges(changes: { [propkey: string]: SimpleChange }): void {
        for (let propName in changes) {
            if (propName === 'selectedConversation') {
                this.chatService.activeConversationId = this.selectedConversation.id;
                this.fetchConversationMessages();
                this.isBlocked = this.selectedConversation.blocked;
                this.isDeleted = this.selectedConversation.status.toString() === 'deleted';
            }
        }
    }

    // ----  METHODS:
    //  Fetch ConversationMessages (on 'OpenConversation' event):
    fetchConversationMessages(): void {
        let self = this;
        //  Reset values:
        this.messages = [];
        this.dataLoading = true;
        this.feedbackMessage = '';
        this.feedbackVisible = false;
        this.feedbackIsError = false;
        this.conversationVisible = false;
        this.scrollToBottom = true;
        //  Timeout duration:
        let timeoutDuration = (this.isMobile) ? 25 : 250;
        //  Request:
        let messagesObservable = this.chatService.getConversationMessages(this.selectedConversation.id);
        messagesObservable
            .subscribe(
            (response) => {
                this.logger.print('Fetch ConversationMessages succeeded: ', response, 'log');
                let messageObjs = response as any;
                //  Messages found?
                if (messageObjs.length > 0) {
                    //  Yes => Store all IncidentUsers in data-bound Array, and determine 'Last seen':
                    messageObjs.forEach(function (messageObj, index) {
                        //  Determine if Message is first of a day:
                        messageObj.isFirstOfDay = (index === 0 || !self.dateService.sameDay(messageObj.createdAt, messageObjs[index - 1].createdAt));
                        let message = new Message(messageObj);
                        //  Add Message Object to ConversationMessages Collection:
                        self.messages.push(message);
                    });
                    this.getTimeAgo();
                }
                //  Show 'No results' / 'User is blocked' / 'User is deleted'-message(s)?
                if (this.isBlocked) {
                    //  Yes => Show error message:
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.BLOCKED.USER',
                        {'name': this.selectedConversation.name}) + '</span>';
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                    this.feedbackIsError = true;
                    this.conversationVisible = true;
                    this.scrollToBottom = false;
                } else {
                    //  No => Does Conversation have Messages?
                    if (this.messages.length === 0) {
                        //  No => Show 'No messages'-feedback:
                        this.feedbackMessage = '<span>' + this.translate.instant(
                            'STRING.CHAT.CONVERSATIONS.MESSAGES.NOT_FOUND',
                            {'name': this.selectedConversation.name}
                        ) + '</span>';
                        //  Also show 'User deleted' feedback if necessary:
                        if (this.isDeleted) {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.DELETED.USER.CONVERSATION.TEXT') + '</span>';
                        }
                        this.feedbackClass = 'warning';
                        this.feedbackVisible = true;
                        this.conversationVisible = false;
                    } else {
                        //  Yes => Hide feedback, unless User is deleted:
                        if (this.isDeleted) {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.DELETED.USER.CONVERSATION.TEXT') + '</span>';
                            this.feedbackClass = 'warning';
                            this.feedbackVisible = true;
                            this.conversationVisible = true;
                            this.scrollToBottom = false;
                        } else {
                            this.feedbackVisible = false;
                            this.conversationVisible = true;
                        }
                    }
                }
            },
            (error) => {
                this.logger.print('Fetch IncidentUsers failed: ', error, 'error');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.CONVERSATIONS.MESSAGES.ERROR.CODE',
                        {'error': error.status, 'name': this.selectedConversation.name}) + '</span>';
                } else {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.CONVERSATIONS.MESSAGES.ERROR.UNKNOWN',
                        {'name': this.selectedConversation.name}) + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
                this.feedbackIsError = true;
            },
            () => {
                setTimeout(() => {
                    this.dataLoading = false;
                }, timeoutDuration);
                //  Scroll down, to where depends on trigger (delayed to allow any css transitions to complete):
                setTimeout(() => {
                    if (this.selectedConversation.highlightedMessage !== null) {
                        //   Trigger = SearchResult => scroll to that specific Message:
                        this.mScrollbarService.scrollTo('#chat-conversation', '#msg_' + this.selectedConversation.highlightedMessage, {
                            scrollEasing: 'easeOut',
                            scrollInertia: this.scrollInertia
                        });
                        //  Add class to apply Highlight CSS animation to specific Message:
                        $('#msg_' + this.selectedConversation.highlightedMessage).addClass('highlight');
                    } else {
                        //  Trigger is ConversationList => scroll to end of list, as the latest message is at the bottom:
                        //  (unless we opened an active conversation - ie. containing at least one message - with a user that deleted his account)
                        if (this.scrollToBottom) {
                            this.mScrollbarService.scrollTo('#chat-conversation', 'bottom', {
                                scrollEasing: 'easeOut',
                                scrollInertia: this.scrollInertia
                            });
                        }
                    }
                    //
                    if (!this.isBlocked && !this.isDeleted) {
                        //  If device is mobile, simply scroll down to ChatWindow:
                        if (this.isMobile) {
                            $([document.documentElement, document.body]).animate({
                                scrollTop: $('.chat-reply').offset().top
                            }, 125);
                        } else {
                            //  If device is not mobile, put focus on 'Reply'-textbox:
                            this.chatReplyInput.nativeElement.focus();
                        }
                    } else {
                        //  Unless User is blocked or deleted => then simply scroll down to ChatWindow:
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $('.chat-reply').offset().top
                        }, 125);
                    }
                }, timeoutDuration);
            }
        );
    }

    //  Handle incoming ConversationMessage (real-time):
    handleIncomingConversationMessage(messageObj: any): void {
        let receivedDate = new Date();
        //  Is incoming Message from selectedConversation:
        if (messageObj.conversationId === this.selectedConversation.id) {
            //  Yes => Add Message to Conversation:
            //  Set 'Date received' and determine if Message is of current day:
            let prevMessage = null;
            let postIsFirstOfDay = null;
            if (this.messages.length > 0) {
                prevMessage = this.messages[this.messages.length - 1];
                postIsFirstOfDay = !this.dateService.sameDay(receivedDate, prevMessage.createdAt);
            } else {
                prevMessage = null;
                postIsFirstOfDay = true;
            }
            //  Create Message Object:
            let newMessage = new Message({
                createdAt: receivedDate,
                content: messageObj.message,
                isMe: false,
                isRead: true,
                isFirstOfDay: postIsFirstOfDay
            });
            //  Add new Message Object to ConversationMessages Collection:
            this.messages.push(newMessage);
            //  Update selectedConversation 'lastMessage' properties (for ConversationList):
            this.selectedConversation.lastMessage = newMessage;
            this.selectedConversation.lastMessageOutput = messageObj.message;
            this.selectedConversation.lastMessageDate = this.dateService.toLocalTime(receivedDate);
            //  Mark active Conversation as read (since it is already active, we can assume the just received Message will be read immediately):
            this.markConversationAsRead();
            //  Scroll down to end of list to show just received Message:
            setTimeout(() => {
                this.mScrollbarService.scrollTo('#chat-conversation', 'bottom', {
                    scrollEasing: 'easeOut',
                    scrollInertia: 250
                });
                this.userMessage = null;
            }, 100);
        } else {
            //  No => Emit Message to update affected Conversation in ConversationList:
            messageObj.createdAt = receivedDate;
            this.inactiveConversationUpdated.emit(messageObj);
        }
    }

    markConversationAsRead(): void {
        this.doMarkConversationAsRead();
    }

    doMarkConversationAsRead(): void {
        //  Request:
        let markConversationObservable = this.chatService.markConversationAsRead(this.selectedConversation.id);
        markConversationObservable
            .subscribe(
                (response) => {
                    this.logger.print('Mark Conversation as read succeeded: ', response, 'log');
                },
                (error) => {
                    this.logger.print('Mark Conversation as read failed: ', error, 'error');
                },
                () => {
                    //  Emit 'Message is read'-event to SocketService (so isRead status can be updated on the Sender side):
                    let payload = { conversationId: this.selectedConversation.id };
                    this.socketService.onSubmitMessageIsRead(payload);
                    //  Clear 'Unread' marker (regardless of successful or unsuccessful API call - gotta keep up appearances!)
                    setTimeout(() => {
                        //  Update unread ConversationMessages for User and store updated UserObj:
                        this.currentUser.unreadMessages = this.currentUser.unreadMessages - this.selectedConversation.unreadMessages;
                        this.userService.setUser(this.currentUser);
                        //  Update Value in ChatUser List:
                        this.selectedConversation.unreadMessages = 0;
                    }, 500);
                }
            );
    }

    deleteConversation(): void {
        let title = this.translate.instant('STRING.CHAT.REMOVE.CONVERSATION');
        let text = this.translate.instant('STRING.CHAT.REMOVE.CONVERSATION.TEXT', {'name': this.selectedConversation.name});
        //
        swal.fire({
            customClass: 'swal-content',
            title: title,
            html: '<p>' + text + '</p>',
            type: 'question',
            cancelButtonText: this.translate.instant('STRING.ACTION.CANCEL'),
            cancelButtonClass: 'button secondary float-left',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('STRING.ACTION.DELETE'),
            confirmButtonClass: 'button primary float-right',
            buttonsStyling: false
        }).then((e) => {
            if (e.value === true) {
                this.doDeleteConversation();
            }
        });
    }

    doDeleteConversation(): void {
        //  Request:
        let deleteConversationObservable = this.chatService.deleteConversationById(this.selectedConversation.id);
        deleteConversationObservable
            .subscribe(
            (response) => {
                this.logger.print('Delete Conversation succeeded: ', response, 'log');
                this.selectedConversationDeleted.emit(this.selectedConversation);
            },
            (error) => {
                this.logger.print('Delete Conversation failed: ', error, 'error');
                let message = this.translate.instant('STRING.CHAT.REMOVE.CONVERSATION.ERROR.UNKNOWN');
                let title = this.translate.instant('STRING.ERROR');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.REMOVE.CONVERSATION.ERROR.CODE', {error: error.status}) + '</span>';
                }
                this.toastr.error(message, title, this.toastrOptions);
            },
            () => {}
        );
    }

    blockConversation(): void {
        let title = (this.selectedConversation.blocked) ? this.translate.instant('STRING.CHAT.UNBLOCK.USER') : this.translate.instant('STRING.CHAT.BLOCK.USER');
        let text = (this.selectedConversation.blocked) ? this.translate.instant('STRING.CHAT.UNBLOCK.USER.TEXT', { 'name' : this.selectedConversation.name }) :
            this.translate.instant('STRING.CHAT.BLOCK.USER.TEXT', { 'name' : this.selectedConversation.name });
        let buttonText = (this.selectedConversation.blocked) ? this.translate.instant('STRING.ACTION.UNBLOCK') : this.translate.instant('STRING.ACTION.BLOCK');
        //
        swal.fire({
            customClass: 'swal-content',
            title: title,
            html: '<p>' + text + '</p>',
            type: 'question',
            cancelButtonText: this.translate.instant('STRING.ACTION.CANCEL'),
            cancelButtonClass: 'button secondary float-left',
            showCancelButton: true,
            confirmButtonText: buttonText,
            confirmButtonClass: 'button primary float-right',
            buttonsStyling: false
        }).then((e) => {
            if (e.value === true) {
                this.doBlockConversation();
            }
        });
    }

    doBlockConversation(): void {
        //  Flip 'blocked' value for Conversation:
        this.selectedConversation.blocked = !this.selectedConversation.blocked;
        //  Request:
        let blockConversationObservable = this.chatService.blockConversationById(this.selectedConversation.id, this.selectedConversation.blocked);
        blockConversationObservable
            .subscribe(
                (response) => {
                    this.logger.print('(Un)Block Conversation succeeded: ', response, 'log');
                    let message = (this.selectedConversation.blocked) ? this.translate.instant('STRING.CHAT.BLOCK.USER.SUCCESS') :
                        this.translate.instant('STRING.CHAT.UNBLOCK.USER.SUCCESS');
                    let title = this.translate.instant('STRING.SUCCESS');
                    this.toastr.success(message, title, this.toastrOptions);
                    //  Update selectedConversation 'lastMessageOutput' property (for in ConversationList):
                    if (this.selectedConversation.blocked) {
                        this.selectedConversation.lastMessageOutput = this.translate.instant('STRING.CHAT.BLOCKED.USER', {'name': this.selectedConversation.name});
                        //  Show 'User is blocked'-message:
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.BLOCKED.USER',
                            {'name': this.selectedConversation.name}) + '</span>';
                        this.feedbackClass = 'error';
                        this.feedbackVisible = true;
                        this.feedbackIsError = true;
                        //  Scroll to start of Conversation (to bring feedback into view):
                        this.mScrollbarService.scrollTo('#chat-conversation', 'top', {
                            scrollEasing: 'easeOut',
                            scrollInertia: this.scrollInertia
                        });
                        //  Emit 'Conversation blocked' event to Chat Server:
                        this.socketService.onBlockConversation(this.selectedConversation.id);
                    } else {
                        if (this.selectedConversation.lastMessage['content'] === undefined) {
                            //  If Conversation has no Messages yet => Show 'Start conversation with ...'-message as 'lastMessage':
                            this.selectedConversation.lastMessageOutput =
                                this.translate.instant('STRING.CHAT.START.CONVERSATION.WITH', {'name': this.selectedConversation.name});
                        } else {
                            this.selectedConversation.lastMessageOutput = this.selectedConversation.lastMessage['content'];
                        }
                        //  Remove 'User is blocked'-message:
                        this.feedbackVisible = false;
                        //  Scroll to end of conversation:
                        this.mScrollbarService.scrollTo('#chat-conversation', 'bottom', {
                            scrollEasing: 'easeOut',
                            scrollInertia: this.scrollInertia
                        });
                        //  Emit 'Conversation unblocked' event to Chat Server:
                        this.socketService.onUnblockConversation(this.selectedConversation.id);
                    }
                },
                (error) => {
                    this.logger.print('(Un)Block Conversation failed: ', error, 'error');
                    let message = (this.selectedConversation.blocked) ? this.translate.instant('STRING.CHAT.UNBLOCK.USER.ERROR.UNKNOWN') :
                        this.translate.instant('STRING.CHAT.BLOCK.USER.ERROR.UNKNOWN');
                    let title = this.translate.instant('STRING.ERROR');
                    if (error.status) {
                        this.feedbackMessage = (this.selectedConversation.blocked) ?
                            '<span>' + this.translate.instant('STRING.CHAT.UNBLOCK.USER.ERROR.CODE', {error: error.status}) + '</span>' :
                            '<span>' + this.translate.instant('STRING.CHAT.BLOCK.USER.ERROR.CODE', {error: error.status}) + '</span>';
                    }
                    this.toastr.error(message, title, this.toastrOptions);
                },
                () => {
                }
            );
    }

    keyDownUserMessage(event): void {
        if (event.key === 'Enter') {
            this.submitUserMessage();
        }
    }

    submitUserMessage(): void {
        //  Prevent submitting empty strings:
        if (!this.userMessage) {
            return;
        }
        this.doSubmitUserMessage();
    }

    doSubmitUserMessage(): void {
        this.feedbackVisible = false;
        //  Request:
        let submitMessageObservable = this.chatService.postMessage(this.userMessage, this.selectedConversation.id);
        submitMessageObservable
            .subscribe(
            (response) => {
                this.logger.print('Send Chat Message succeeded: ', response, 'log');
                //  Create new Message Object:
                let postedDate = new Date();
                let prevMessage = null;
                let postIsFirstOfDay = null;
                if (this.messages.length > 0) {
                    prevMessage = this.messages[this.messages.length - 1];
                    postIsFirstOfDay = !this.dateService.sameDay(postedDate, prevMessage.createdAt);
                } else {
                    prevMessage = null;
                    postIsFirstOfDay = true;
                }
                let newMessage = new Message({
                    createdAt: postedDate,
                    content: this.userMessage,
                    isMe: true,
                    isRead: false,
                    isFirstOfDay: postIsFirstOfDay
                });
                //  Add new Message Object to ConversationMessages Collection:
                this.messages.push(newMessage);
                //  Update selectedConversation 'lastMessage' properties (for ConversationList):
                this.selectedConversation.lastMessage = newMessage;
                this.selectedConversation.lastMessageOutput = this.userMessage;
                this.selectedConversation.lastMessageDate = this.dateService.toLocalTime(postedDate);
                //  Show Conversation and hide feedback if submitted Message is first Message of Conversation:
                if (!this.conversationVisible) {
                    this.conversationVisible = true;
                    this.feedbackVisible = false;
                }
                //  Scroll down to end of list to show just posted Message:
                setTimeout(() => {
                    this.mScrollbarService.scrollTo('#chat-conversation', 'bottom', {
                        scrollEasing: 'easeOut',
                        scrollInertia: 250
                    });
                    this.userMessage = null;
                }, 100);
                //  Emit 'Message sent'-event to SocketService:
                let payload = {
                    conversationId: this.selectedConversation.id,
                    message: this.userMessage
                };
                this.socketService.onSubmitMessage(payload);
                this.activeConversationUpdated.emit();
            },
            (error) => {
                this.logger.print('Send Chat Message failed: ', error, 'error');
            },
            () => {}
        );
    }

    // ----  LISTENERS:
    //  Listener for incoming Messages:
    initMessageListener(): void {
        this.incomingMessageObservable = this.chatService.incomingMessage;
        this.incomingMessageObservable
            .subscribe(
                (messageObj) => {
                    this.handleIncomingConversationMessage(messageObj);
                },
                () => {},
                () => {}
            );
    }

    // ----  MOBILE-ONLY:
    scrollToUserList(): void {
        $([document.documentElement, document.body]).animate({
            scrollTop: $('#wrapper').offset().top
        }, 125);
    }

    toggleMobileUserMenu() {
        this.mobileMenuVisible = !this.mobileMenuVisible;
    }

    //  Android-only:
    fitConversationPaneForAndroid(isMinimized: boolean): void {
        if (this.isMobile && this.deviceService.os.toLowerCase() === 'android') {
            let body = document.getElementsByTagName('body')[0];
            if (isMinimized) {
                this.conversationCssClass = 'minimized';
                setTimeout( () => {
                    this.mScrollbarService.scrollTo('#chat-conversation', 'bottom', {
                        scrollEasing: 'easeOut',
                        scrollInertia: this.scrollInertia
                    });
                    body.classList.add('overlayed');
                    this.mScrollbarService.update('#chat-conversation');
                }, 250);
            } else {
                this.conversationCssClass = '';
                setTimeout(() => {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $('.chat-reply').offset().top
                    }, 125);
                    body.classList.remove('overlayed');
                    this.mScrollbarService.update('#chat-conversation');
                }, 250);
            }
        }
    }

    // ----  HELPERS:
    getTimeAgo(): void {
        if (this.messages.length > 0) {
            this.lastSeen = this.dateService.getTimeAgo(this.selectedConversation.lastSeen);
        } else {
            this.lastSeen = false;
        }
    }
}
