import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { IncidentService } from '../incident.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

@Component({
    selector: 'hss-incident-register-with-code',
    templateUrl: 'incident-register-with-code.component.html'
})

export class IncidentRegisterWithCodeComponent implements OnInit {
    currentUser: any;
    isVolunteer: boolean;
    incidentRole: string;
    incidentRoles: any = [];
    uniqueId: string;
    //
    isSubmitting = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private router: Router,
        private incidentService: IncidentService,
        private logger: LoggingService,
        public translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
        this.isVolunteer = this.userService.isVolunteer();
        //  Must use timeout for translations to apply (even if set to ridiculously small value like 25ms), just 'cause:
        setTimeout(() => {
            this.getUserRoles();
        }, 25);
    }

    //  Get all available UserRoles (except Volunteer role):
    getUserRoles(): void {
        this.incidentRoles = this.userService.getUserIncidentRoles(false);
    }

    onSubmitUniqueId(): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        event.preventDefault();
        //  Input validation:
        if (!this.uniqueId) {
            field = this.translate.instant('STRING.UNIQUE_ID');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.incidentRole) {
            field = this.translate.instant('STRING.RESUSCITATION.ROLE');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitUniqueId();
        }
    }

    doSubmitUniqueId(): void {
        let postData = {
            incidentToken: this.uniqueId,
            incidentRole: this.incidentRole
        };
        //  Request:
        this.isSubmitting = true;
        let addIncidentObservable = this.incidentService.addIncident(postData, this.currentUser.id);
        addIncidentObservable
            .pipe(finalize(() =>
                setTimeout( () => {
                    this.isSubmitting = false;
                }, 500)
            ))
            .subscribe(
            (response) => {
                this.logger.print('Adding Incident succeeded', response, 'log');
                this.feedbackClass = 'success';
                this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.REGISTER.WITH_CODE.SUBMIT.SUCCESS') + '</span>';
                this.feedbackVisible = true;
                //  Clear form:
                this.uniqueId = '';
                this.incidentRole = null;
            },
            (error) => {
                this.logger.print('Adding Incident failed', error, 'error');
                if (error.status) {
                    if (error.status === 400 && error.error['message'] === 'The user is already involved with the requested Incident') {
                        //  Error: CurrentUser already added to Incident:
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.REGISTER.WITH_CODE.SUBMIT.ERROR.ALREADY_ADDED') + '</span>';
                    } else if (error.status === 400 && error.error['message'] === 'The provided IncidentToken does not match any incident') {
                        //  Error: submitted Incident Code invalid or unknown:
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.REGISTER.WITH_CODE.SUBMIT.ERROR.UNKNOWN_INCIDENT') + '</span>';
                    } else {
                        //  Error: generic message with status code:
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.REGISTER.WITH_CODE.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                    }
                } else {
                    //  Error: generic message without status code:
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.REGISTER.WITH_CODE.SUBMIT.ERROR.UNKNOWN') + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
            },
            () => {}
        );
    }

    navigate(route: string): void {
        this.router.navigate(['/' + route]);
    }
}
