import { Injectable, OnInit } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { of } from 'rxjs';
// import 'rxjs/add/observable/of';

import { ApiService } from './api.service';
import { LoggingService } from './logging.service';
import { TranslateService } from '@ngx-translate/core';

import { User } from '@models/user.model';

import * as moment from 'moment';

@Injectable()
export class UserService implements OnInit {
    public currentUser: any;
    public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(this.currentUser);

    constructor(
        private apiService: ApiService,
        private logger: LoggingService,
        private translate: TranslateService
    ) {
        if (localStorage.getItem('currentUser')) {
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
    }

    // ----  LIFECYCLE:
    ngOnInit(): void {}

    //  Get user data of CurrentUser:
    getUserData(): Observable<Response> {
        //  Request options:
        let endpoint = '/users/@me';
        let type = 'GET';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Update user data of User with ID 'userId':
    updateUserData(postData: any, userId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/users/' + userId;
        let type = 'PUT';
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }

    //  Delete user data of User with ID 'userId':
    deleteUserData(userId: number): Observable<Response> {
        //  Request options:
        let endpoint = '/users/' + userId;
        let type = 'DELETE';
        //  Request:
       return this.apiService.apiCall(endpoint, type);
    }

    //  Update avatar of CurrentUser:
    updateUserAvatar(postData: any): Observable<Response> {
        //  Request options:
        let endpoint = '/users/@me/image';
        let type = 'POST_FILE';
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }

    //  Delete avatar of CurrentUser:
    deleteUserAvatar(): Observable<Response> {
        //  Request options:
        let endpoint = '/users/@me/image';
        let type = 'DELETE';
        //  Request:
        return this.apiService.apiCall(endpoint, type);
    }

    //  Change User Password:
    changeUserPassword(postData: any): Observable<Response> {
        //  Request options:
        let endpoint = '/users/@me/change-password';
        let type = 'POST';
        //  Request:
        return this.apiService.apiCall(endpoint, type, postData);
    }

    // ---  Get/Set Methods:
    //  User Object:
    getUser() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return this.currentUser;
    }
    setUser(userObj): void {
        this.currentUser = new User(userObj);
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        //  Update UserSubject:
        this.setUserSubject();
        this.logger.print('CurrentUser stored in LocalStorage', this.currentUser, 'log');
    }

    //  User BehaviorSubject:
    getUserSubject(): Observable<any> {
        return this.currentUserSubject.asObservable();
    }
    setUserSubject(): void {
        this.currentUserSubject.next(this.currentUser);
    }

    //  User Language:
    getUserLanguage(): string {
        return this.currentUser.preferredLanguage.toLowerCase();
    }
    setUserLanguage(language: string): void {
        this.currentUser.preferredLanguage = language;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        //  Update ngx-Translate & Moment.js Locale:
        this.translate.use(language);
        moment.locale(language);
        //
        this.logger.print('User updated, language set to', language.toUpperCase(), 'log');
    }

    //  Check if User is Admin:
    isAdmin(): boolean {
        let isAdmin = false;
        this.currentUser.roles.forEach( function(role) {
            role.toLowerCase();
            if (role.includes('admin')) {
                isAdmin = true;
            }
        });
        return isAdmin;
    }

    //  Check if User is Volunteer:
    isVolunteer(): boolean {
        let isVolunteer = false;
        this.currentUser.incidentRoles.forEach( function(role) {
            role.toLowerCase();
            if (role.includes('volunteer')) {
                isVolunteer = true;
            }
        });
        return isVolunteer;
    }

    //  Check if User is Volunteer:
    isVolunteerOnly(): boolean {
        let isVolunteer = false;
        if (this.currentUser.incidentRoles.length === 1) {
            isVolunteer = (this.currentUser.incidentRoles[0].toLowerCase() === 'volunteer');
        }
        return isVolunteer;
    }

    //  Get User Incident Roles (Bystander, Victim, etc.):
    //  Temporary: can be removed as soon as System/Incident Roles are separated!
    getUserIncidentRoles(includeVolunteerRole: boolean): any {
        let incidentRoles = [];
        this.currentUser.incidentRoles.forEach( function(role) {
            role.toLowerCase();
            //  Filter out Admin/User roles:
            if (!role.includes('user') && !role.includes('admin')) {
                //  Filter out Volunteer unless requested:
                if (!role.includes('volunteer') || (role.includes('volunteer') && includeVolunteerRole)) {
                    incidentRoles.push(role);
                }
            }
        });
        return incidentRoles;
    }

    //  Get All Incident Roles (Bystander, Victim, etc.):
    getIncidentRoles(includeVolunteerRole: boolean): any {
        let roles = [
            {
                name: this.translate.instant('STRING.RESUSCITATION.ROLE_BYSTANDER'),
                value: 'bystander'
            },
            {
                name: this.translate.instant('STRING.RESUSCITATION.ROLE_PARTNER'),
                value: 'partner'
            },
            {
                name: this.translate.instant('STRING.RESUSCITATION.ROLE_PROFESSIONAL'),
                value: 'professional'
            },
            {
                name: this.translate.instant('STRING.RESUSCITATION.ROLE_RELATIVE'),
                value: 'relative'
            },
            {
                name: this.translate.instant('STRING.RESUSCITATION.ROLE_VICTIM'),
                value: 'victim'
            }
        ];
        //  Include Volunteer role if requested:
        if (includeVolunteerRole) {
            roles.push(
                {
                    name: this.translate.instant('STRING.RESUSCITATION.ROLE_VOLUNTEER'),
                    value: 'volunteer'
                }
            );
        }
        //
        return roles;
    }
}
