import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

import { DateService } from '@services/date.service';
import { TranslateService } from '@ngx-translate/core';

import { Conversation } from '../conversation.model';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-conversation-result-item, [conversationResultItem]',
    templateUrl: 'conversation-result-item.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})

export class ConversationResultItemComponent implements OnInit {
    @Input() conversation: Conversation;
    @Input() isActive: boolean;
    @Input() preselectedConversationId: number;

    public isDeleted: boolean;
    public isVisible: boolean;

    constructor(
        public dateService: DateService,
        public translate: TranslateService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        if (this.conversation) {
            this.isDeleted = (this.conversation.status === 'deleted');
            //  Conversation is visible by default:
            this.isVisible = true;
            //  Hide reset Conversation, unless it is explicitly requested (ie. url/redirect with urlParameter 'id' or NavigationExtra 'conversationId'):
            if (this.preselectedConversationId) {
                if (this.conversation.status === 'conversation_deleted' && this.conversation.inOrOut === 'out' &&
                    this.preselectedConversationId.toString() !== this.conversation.id.toString()) {
                    this.isVisible = false;
                }
            } else {
                if (this.conversation.status === 'conversation_deleted' && this.conversation.inOrOut === 'out') {
                    this.isVisible = false;
                }
            }
            //  Conversation User avatar:
            this.conversation.image = (this.conversation.image) ? environment.c_uploadUrl + this.conversation.image : null;
        }
    }
}
