import { Component } from '@angular/core';
import { OnInit } from '@angular/core';

import { UserService } from '@services/user.service';

import { routerAnimation } from '@animations/animations';

@Component({
    selector: 'hss-dashboard-core',
    templateUrl: 'dashboard-core.component.html',
    animations: [ routerAnimation() ]
})

export class DashboardCoreComponent implements OnInit {
    public currentUser: any = null;
    public notificationCounter: number;
    public navigationVisible: boolean;

    constructor(
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.initUserData();
        //  Hide menu by default:
        this.navigationVisible = false;
    }

    initUserData(): void {
        this.currentUser = this.userService.getUser();
        //  Set Notification-counter (unreadMessages + openRequests):
        this.notificationCounter = this.currentUser.unreadMessages + this.currentUser.contactRequestCount;
        //  Subscribe 'Unread Messages' / 'Open ContactRequests' to User BehaviorSubject (for real-time updates):
        this.userService.getUserSubject().subscribe(user => {
            if (user) {
                this.notificationCounter = user.unreadMessages + user.contactRequestCount;
            }
        });
    }

    toggleNavigation(): void {
        this.navigationVisible = !this.navigationVisible;
        //  Lock body scrolling if sidebar is visible:
        const html = document.getElementsByTagName('html')[0];
        if (this.navigationVisible) {
            html.classList.add('locked');
        } else {
            html.classList.remove('locked');
        }
    }

    //  Router Transitions:
    getRouterOutletState(outlet) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }
}
