export class Conversation {
    id: number;
    name: string;
    image: string;
    role: string;
    lastMessage: any;
    lastMessageOutput: string;
    lastMessageDate: string;
    lastSeen: string;
    highlightedMessage: number = null;
    unreadMessages: number;
    incident: any;
    status: string;
    inOrOut: string;
    blocked: boolean;
    cssClass: string;
    unsortedIndex: number;

    constructor(conversationObj) {
        this.id = conversationObj.id;
        this.name = conversationObj.name;
        this.image = conversationObj.image;
        this.lastMessage = conversationObj.lastMessage;
        this.lastSeen = conversationObj.lastSeen;
        this.unreadMessages = conversationObj.unreadMessages;
        this.highlightedMessage = null;
        this.incident = null;
        this.status = conversationObj.status;
        this.inOrOut = conversationObj.inOrOut;
        this.blocked = conversationObj.blocked;
        this.unsortedIndex = 0;
    }
}
