import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { CoreService } from '../core.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-password-reset',
    templateUrl: 'password-reset.component.html'
})

export class PasswordResetComponent implements OnInit {
    introText: string;
    passwordNew: string;
    passwordNewRepeat: string;
    confirmationToken: string = null;
    private readonly minPasswordLength = 8;
    hasToken = false;
    //
    isSubmitting = false;
    hasSubmitted = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;
    //
    public readonly hssRegisterUrl = environment.c_hssRegisterUrl;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private coreService: CoreService,
        private logger: LoggingService,
        public translate: TranslateService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Get confirmationToken:
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.confirmationToken = params['confirmationToken'];
        });
        //  Must use timeout for translations to apply (even if set to ridiculously small value like 25ms), just 'cause:
        setTimeout(() => {
            //  Is Confirmation Token present?
            if (this.confirmationToken) {
                //  Token present:
                this.hasToken = true;
                //  Intro text:
                this.introText = this.translate.instant('STRING.PASSWORD.RESET.DESCRIPTION') + '<br>' +
                    this.translate.instant('STRING.PASSWORD.REQUIREMENTS', {'numChars': this.minPasswordLength});
            } else {
                //  Token not present:
                this.feedbackClass = 'error';
                this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.RESET.TOKEN.NOT_FOUND') + '</span>';
                this.feedbackVisible = true;
            }
        }, 25);
    }

    onSubmitResetPassword(): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        event.preventDefault();
        //  Per-field Input Validation:
        if (!this.confirmationToken) {
            field = this.translate.instant('STRING.PASSWORD.RESET.TOKEN');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FOUND', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  'New password' filled in:
        if (!this.passwordNew) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  'Repeat new password' filled in:
        if (!this.passwordNewRepeat) {
            field = this.translate.instant('STRING.PASSWORD.REPEAT');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password too short:
        if (this.passwordNew && this.passwordNew.length < this.minPasswordLength) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.TOO_SHORT',
                {'field': field, 'minLength': this.minPasswordLength}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password invalid:
        if (this.passwordNew && !this.coreService.isValidPassword(this.passwordNew)) {
            field = this.translate.instant('STRING.PASSWORD');
            feedback += '<span>' + this.translate.instant('FEEDBACK.REQUIREMENTS_NOT_MET', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //  Password and Repeat Password not equal:
        if ((this.passwordNew && this.passwordNewRepeat) && (this.passwordNew !== this.passwordNewRepeat)) {
            field = this.translate.instant('STRING.PASSWORD');
            let field2 = this.translate.instant('STRING.PASSWORD.REPEAT');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_EQUAL', {'field': field, 'field2': field2}) + '</span>';
            this.feedbackVisible = true;
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitResetPassword();
        }
    }

    doSubmitResetPassword(): void {
        let postData = {
            password: this.passwordNew,
            passwordRepeat: this.passwordNewRepeat,
            confirmationToken: this.confirmationToken
        };
        //  Request:
        this.isSubmitting = true;
        let passwordResetObservable = this.coreService.resetPassword(postData);
        passwordResetObservable
            .pipe(finalize(() =>
                setTimeout(() => {
                    this.isSubmitting = false;
                }, 500)
            ))
            .subscribe(
                (response) => {
                    this.logger.print('Submit PasswordReset succeeded', response, 'log');
                    this.feedbackClass = 'success';
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.RESET.SUBMIT.SUCCESS') + '</span>';
                    this.feedbackVisible = true;
                    //  Disable 'Submit'-button after successful submission:
                    this.hasSubmitted = true;
                },
                (error) => {
                    this.logger.print('Submit PasswordReset failed', error, 'error');
                    if (error.status) {
                        if (error.status === 404) {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.RESET.SUBMIT.ERROR.TOKEN.INVALID', {error: error.status}) + '</span>';
                        } else {
                            this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.RESET.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                        }
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.PASSWORD.RESET.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                    //  Enable 'Submit'-button after failed submission:
                    this.hasSubmitted = false;
                },
                () => {}
            );
    }

    navigate(route): void {
        this.router.navigate([route]);
    }
}
