import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '@services/authentication.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

@Component({
    selector: 'hss-dashboard-sidebar, [dashboardSidebar]',
    templateUrl: 'dashboard-sidebar.component.html'
})

export class DashboardSidebarComponent implements OnInit {
    @Input() navigationVisible: boolean;
    public currentUser: any = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private logger: LoggingService,
        private translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        this.currentUser = this.userService.getUser();
        this.logger.print('Logged in as', this.currentUser, 'log');
    }
}
