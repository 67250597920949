import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '@services/authentication.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

@Component({
    selector: 'hss-request-confirm',
    templateUrl: 'request-confirm.component.html'
})

export class RequestConfirmComponent implements OnInit {
    requestConfirmationToken = null; // URL parameter
    isLoading = true;
    //
    tokenInvalidVisible = false;
    tokenErrorVisible = false;
    tokenSuccessVisible = false;
    //
    public readonly hssRegisterUrl = environment.c_hssRegisterUrl;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private logger: LoggingService,
        public translate: TranslateService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Get RequestConfirmation Token from url:
        this.activatedRoute.queryParams.subscribe(params => {
            this.requestConfirmationToken = params['confirmationToken'];
        });
        //  Validate RequestConfirmation Token:
        this.onSubmitRequestConfirmationToken();
    }

    //  Check if all requirements to validate RequestConfirmation Token are met:
    onSubmitRequestConfirmationToken(): void {
        if (this.requestConfirmationToken === null) {
            //  Token not set => show feedback:
            setTimeout( () => {
                this.isLoading = false;
                this.tokenInvalidVisible = true;
            }, 750);
        } else {
            //  Token set => Submit RequestConfirmation Token:
            this.doSubmitRequestConfirmationToken();
        }
    }

    //  Submit RequestConfirmation Token:
    doSubmitRequestConfirmationToken(): void {
        let requestConfirmationObservable = this.authenticationService.confirmRequest(this.requestConfirmationToken);
        requestConfirmationObservable.subscribe(
            (response) => {
                this.logger.print('Confirm Request with Token succeeded', response, 'log');
                this.tokenSuccessVisible = true;
                //  Update view:
                setTimeout(() => {
                    this.isLoading = false;
                    this.tokenSuccessVisible = true;
                }, 500);
            },
            (error) => {
                this.logger.print('Confirm Request with Token failed', error, 'error');
                //  Update view:
                setTimeout(() => {
                    this.isLoading = false;
                    this.tokenErrorVisible = true;
                }, 500);
            },
            () => {}
        );
    }

    navigate(route): void {
        this.router.navigate([route]);
    }
}
