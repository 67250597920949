import { Component } from '@angular/core';
import { OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ChatService } from '../chat.service';
import { DateService } from '@services/date.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoggingService } from '@services/logging.service';
import { SocketService } from '@services/socket.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import { Conversation } from '../conversation.model';

import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';

import { FadeAnimation } from '@animations/animations';

@Component({
    selector: 'hss-chat-core',
    templateUrl: 'chat-core.component.html',
    animations: [FadeAnimation(125)]
})

export class ChatCoreComponent implements OnInit, AfterViewInit, OnDestroy {
    private currentUser: any;
    conversations: Conversation[] = [];
    results: any[] = [];
    selectedConversation: Conversation;
    selectedConversationIndex: number = null;
    preselectedConversationId: string = null; // URL parameter
    listSearchResults = false; // URL parameter
    chatMessageReadObservable = null; // Listener for incoming 'Message read' events:
    //
    isMobile: boolean;
    //  Toastr:
    private toastrOptions = {
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        timeOut: 5000
    } as any;
    private scrollbarOptions = {
        axis: 'y',
        theme: 'custom-theme',
        alwaysShowScrollbar: 0,
        mouseWheel: { enable: true},
        scrollButtons: { enable: false }
    } as any;
    //
    errorMessage = null;
    visibilityState: string;
    listVisibilityState: string;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef,
        private chatService: ChatService,
        private dateService: DateService,
        private deviceService: DeviceDetectorService,
        private logger: LoggingService,
        private socketService: SocketService,
        private mScrollbarService: MalihuScrollbarService,
        private toastr: ToastrService,
        public translate: TranslateService,
        private userService: UserService
    ) {
        //  Check for NavigationExtras or urlParameter 'id': in this case used to pre-select a Conversation:
        const navigation = this.router.getCurrentNavigation();
        if (navigation) {
            if (navigation.extras.state) {
                //  NavigationExtras exists => check if ConversationId is included:
                const state = navigation.extras.state as { conversationId: string };
                //  Extra check to see if 'conversationId' is set in NavigationExtras:
                if (state.conversationId) {
                    //  'conversationId' is set => Set 'preselectedConversationId' so we can pre-select the right Conversation:
                    this.preselectedConversationId = state.conversationId;
                }
            } else {
                //  NavigationExtras does not exist => check if urlParameter 'id' is set:
                this.preselectedConversationId = this.activatedRoute.snapshot.paramMap.get('id');
            }
        }
    }

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
        //  Are we dealing with a mobile device?
        this.isMobile = this.deviceService.isMobile();
        //  Fetch Conversations:
        this.fetchConversations();
        //  Real-time Chat:
        // this.initIncomingMessageReadStream();
        //  Visibility State of Chat Window:
        this.visibilityState = 'visible';
        //  init. listener for incoming 'Message read' events (real-time):
        this.initMessageReadListener();
    }

    ngAfterViewInit() {
        this.mScrollbarService.initScrollbar('#chat-users', this.scrollbarOptions);
    }

    ngOnDestroy() {
        this.mScrollbarService.destroy('#chat-users');
    }

    // ----  METHODS:
    //  Fetch Conversations:
    fetchConversations(): void {
        let self = this;
        //  Reset collections:
        this.conversations = [];
        this.results = [];
        //  Request:
        let conversationsObservable = this.chatService.getConversations();
        conversationsObservable
            .subscribe(
            (response) => {
                this.logger.print('Fetch Conversations successful', response, 'log');
                let conversationObjs = response as any;
                //  Incidents found?
                if (conversationObjs.length === 0) {
                    //  No => Show feedback:
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.CONVERSATIONS.NOT_FOUND') + '</span>';
                    this.feedbackClass = 'warning';
                    this.feedbackVisible = true;
                } else {
                    //  Yes => Sort response data by lastMessageDate (regardless of number of unread Messages):
                    conversationObjs.sort( (a, b) => {
                        return this.dateService.getJsDateObj(new Date(b.lastMessage.createdAt)) - this.dateService.getJsDateObj(new Date(a.lastMessage.createdAt));
                    });
                    //  Store all Incidents in data-bound Array:
                    conversationObjs.forEach(function (conversationObj, index) {
                        let content;
                        let conversation = new Conversation(conversationObj);
                        //  Is Contact blocked?
                        if (conversation.blocked) {
                            //  Yes => Show 'User is blocked'-message as 'lastMessage':
                            content = self.translate.instant('STRING.CHAT.BLOCKED.USER', {'name': conversationObj.name});
                            conversation.lastMessageOutput = content;
                        } else {
                            //  No => Show last Message from Conversation:
                            if (conversation.lastMessage['content'] === undefined) {
                                //  If Conversation has no Messages yet => Show 'Start conversation with ...'-message as 'lastMessage':
                                content = self.translate.instant('STRING.CHAT.START.CONVERSATION.WITH', {'name': conversationObj.name});
                                conversation.lastMessageOutput = content;
                            } else {
                                conversation.lastMessageOutput = conversation.lastMessage['content'];
                            }
                        }
                        //  Add 'Incident' to Conversation (do not use Incident Model [too different from the Incident data in response):
                        conversation.incident = conversationObj.incident;
                        //  Format date of last Comment (hh:mm if < 24 hours old, abbrev. name of day if < 168 hours-  1 week - old, else DD-MM):
                        if (conversation.lastMessage.createdAt) {
                            let hoursAgo = self.dateService.hoursAgo(conversation.lastMessage.createdAt);
                            if (hoursAgo < 24) {
                                conversation.lastMessageDate = self.dateService.toLocalTime(conversation.lastMessage.createdAt);
                            } else if (hoursAgo >= 24 && hoursAgo < 168) {
                                conversation.lastMessageDate = self.dateService.toLocalMediumDayName(conversation.lastMessage.createdAt);
                            } else {
                                conversation.lastMessageDate = self.dateService.toLocalShortestDate(conversation.lastMessage.createdAt);
                            }
                        } else {
                            conversation.lastMessageDate = null;
                        }
                        self.conversations.push(conversation);
                        //  If ConversationId URL parameter is set:
                        if (self.preselectedConversationId !== null) {
                            //  Need to cast conversation.id to String, because Angular refuses to cast self.preselectedConversationId to a number:
                            if (conversation.id.toString() === self.preselectedConversationId.toString()) {
                                self.selectedConversationIndex = index;
                            }
                        }
                    });
                    //  Set Conversation as active:
                    if (this.selectedConversationIndex !== null) {
                        //  ConversationId URL parameter is set => set preselected Conversation as active:
                        this.setPreselectedConversation();
                    } else {
                        console.log('isMobile: ' + this.isMobile);
                        //  [DESKTOP-only] - ConversationId URL parameter is unset => set first Conversation in Conversations array as active:
                        if (!this.isMobile) {
                            this.setSelectedConversation(0);
                        }
                    }
                }
            },
            (error) => {
                this.logger.print('Fetch Conversations failed', error, 'error');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.CONVERSATIONS.ERROR.CODE', {error: error.status}) + '</span>';
                } else {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.CONVERSATIONS.ERROR.UNKNOWN') + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
            },
            () => {}
        );
    }

    // Return a specific sorted array of conversations
    getSortedConversations(): Conversation[] {
        const unread: Conversation[] = [];
        const normal: Conversation[] = [];
        const deleted: Conversation[] = [];
        // get items with status unread
        this.conversations.forEach( function(conversation: Conversation, index) {
            conversation.unsortedIndex = index;
            if (conversation.unreadMessages > 0) {
                unread.push(conversation);
            } else if (conversation.status == 'deleted') {
                deleted.push(conversation);
            } else {
                normal.push(conversation);
            }
        });

        // make sure the normal items are sorted by date
        normal.sort((a: Conversation, b: Conversation) => {
            const d1: Date = new Date(a.lastMessage.createdAt);
            const d2: Date = new Date(b.lastMessage.createdAt);
            if (d1 < d2) {
                return 1;
            } else if (d1 > d2) {
                return -1;
            } else {
                return 0;
            }
        });

        return unread.concat(normal).concat(deleted);
    }

    //  Init. selected Conversation (when ConversationId URL parameter is not set onInit, and when selecting Conversation from list):
    setSelectedConversation(index: number): void {
        //  Timeout duration:
        let timeoutDuration = (this.isMobile) ? 37 : 375;
        //  What do we need to do?
        if (index === null) {
            //  SearchResults found:
            //  Fade out Chat Window:
            this.visibilityState = 'hidden';
            //  Fade in ConversationList:
            if (this.listVisibilityState !== 'visible') {
                //  Fade in ConversationList:
                setTimeout (() => {
                    this.listVisibilityState = 'visible';
                }, timeoutDuration);
            }
        } else if (index === this.selectedConversationIndex && !this.listSearchResults) {
            //  Active Conversation clicked => no need to update selectedConversation:
            return;
        } else {
            //  Non-active Conversation => update selectedConversation:
            this.selectedConversationIndex = index;
            //  Fade out ChatWindow (for smooth transition):
            this.visibilityState = 'hidden';
            //  Set selected Conversation as active:
            setTimeout(() => {
                //  Bind selected Conversation to ChatWindow:
                // this.selectedConversation = this.conversations[this.selectedConversationIndex];
                if (this.conversations && this.conversations.length > 0) {
                    this.selectedConversation = this.conversations[this.selectedConversationIndex];
                } else if (this.results && this.results.length > 0) {
                    this.selectedConversation = this.results[this.selectedConversationIndex];
                }
                this.cd.detectChanges();
                //  Mark Conversation as 'read':
                if (this.selectedConversation.unreadMessages) {
                    this.markConversationAsRead();
                }
                //  Fade in Chat Window:
                this.visibilityState = 'visible';
                //  Fade in ConversationList:
                if (this.listVisibilityState !== 'visible') {
                    this.listVisibilityState = 'visible';
                }
            }, timeoutDuration);
        }
    }

    //  Init. pre-selected Conversation (ConversationId URL parameter is set onInit):
    setPreselectedConversation(): void {
        //  Timeout duration:
        let timeoutDuration = (this.isMobile) ? 37 : 375;
        //  Set selected Conversation as active:
        setTimeout(() => {
            //  Bind selected Conversation to ChatWindow:
            this.selectedConversation = this.conversations[this.selectedConversationIndex];
            //  Mark Conversation as 'read':
            this.markConversationAsRead();
            //  Fade in Chat Window:
            this.visibilityState = 'visible';
            //  Fade in ConversationList:
            if (this.listVisibilityState !== 'visible') {
                this.listVisibilityState = 'visible';
            }
        }, timeoutDuration);
    }

    //  Delete selected Conversation (emitted from ConversationWindow component when 'Delete Conversation'-button is clicked):
    deleteSelectedConversation(deletedConversation: Conversation): void {
        //  Get index of affected Conversation in Conversations Collection:
        let deletedConversationIndex = -1;
        this.conversations.forEach( function(conversation: Conversation, index) {
            if (conversation.id === deletedConversation.id) {
                deletedConversationIndex = index;
                return;
            }
        });
        //  If affected Conversation is found in Conversations Collection, delete it:
        if (deletedConversationIndex > -1) {
            this.conversations.splice(deletedConversationIndex, 1);
            //  Conversations remaining after deletion?
            if (this.conversations.length > 0) {
                //  Yes => Set first in list as active:
                this.selectedConversationIndex = null;
                this.setSelectedConversation(0);
            } else {
                //  No => Show feedback:
                this.feedbackMessage = '<span>' + this.translate.instant('STRING.CHAT.CONVERSATIONS.NOT_FOUND') + '</span>';
                this.feedbackClass = 'warning';
                this.feedbackVisible = true;
            }
            //  Show feedback in Toastr:
            let message = this.translate.instant('STRING.CHAT.REMOVE.CONVERSATION.SUCCESS');
            let title = this.translate.instant('STRING.SUCCESS');
            this.toastr.success(message, title, this.toastrOptions);
        }
    }

    //  Update active Conversation (emitted from ConversationWindow component when new Message of non-selected Conversation is received through real-time chat):
    updateActiveConversation() {
        //  Sort ConversationList by lastMessageDate, which automatically pushes the affected Conversation to the top of the ConversationList:
        this.conversations.sort( (a, b) => {
            return this.dateService.getJsDateObj(new Date(b.lastMessage.createdAt)) - this.dateService.getJsDateObj(new Date(a.lastMessage.createdAt));
        });
    }

    //  Update inactive Conversation (emitted from ConversationWindow component when new Message of non-selected Conversation is received through real-time chat):
    updateInactiveConversation(updatedConversationObj: any): void {
        //  Get index of affected Conversation in Conversations Collection:
        let updatedConversationIndex = -1;
        this.conversations.forEach( function(conversation: Conversation, index) {
            if (conversation.id === updatedConversationObj.conversationId) {
                updatedConversationIndex = index;
                return;
            }
        });
        //  Is affected Conversation found in Conversations Collection?
        if (updatedConversationIndex > -1) {
            //  Yes =>  update ConversationList item (date, message preview):
            let updatedConversation = this.conversations[updatedConversationIndex];
            updatedConversation.unreadMessages++;
            updatedConversation.lastMessageDate = this.dateService.toLocalTime(updatedConversationObj.createdAt);
            updatedConversation.lastMessageOutput = updatedConversationObj.message;
            //  Sort ConversationList by lastMessageDate, which automatically pushes the affected Conversation to the top of the ConversationList:
            this.conversations.sort( (a, b) => {
                return this.dateService.getJsDateObj(new Date(b.lastMessageDate)) - this.dateService.getJsDateObj(new Date(a.lastMessageDate));
            });
        }
    }

    markConversationAsRead(): void {
        //  Only mark Conversation as read if it has unread messages:
        if (this.selectedConversation.unreadMessages && this.selectedConversation.unreadMessages > 0) {
            this.doMarkConversationAsRead();
        }
    }

    doMarkConversationAsRead(): void {
        console.log('YO');
        //  Request:
        let markConversationObservable = this.chatService.markConversationAsRead(this.selectedConversation.id);
        markConversationObservable
            .subscribe(
            (response) => {
                this.logger.print('Mark Conversation as read succeeded: ', response, 'log');
            },
            (error) => {
                this.logger.print('Mark Conversation as read failed: ', error, 'error');
            },
            () => {
                //  Emit 'Message is read'-event to SocketService (so isRead status can be updated on the Sender side):
                let payload = { conversationId: this.selectedConversation.id };
                this.socketService.onSubmitMessageIsRead(payload);
                //  Clear 'Unread' marker (regardless of successful or unsuccessful API call - gotta keep up appearances!)
                setTimeout(() => {
                    //  Update unread ConversationMessages for User and store updated UserObj:
                    this.currentUser.unreadMessages = this.currentUser.unreadMessages - this.selectedConversation.unreadMessages;
                    this.userService.setUser(this.currentUser);
                    //  Update Value in ChatUser List:
                    this.selectedConversation.unreadMessages = 0;
                }, 500);
            }
        );
    }

    /** @namespace searchResults.users **/
    onSearchResults(searchResults): void {
        //  Hide elements before rendering results:
        this.visibilityState = 'hidden';
        this.listVisibilityState = 'hidden';
        setTimeout( () => {
            //  Reset collections:
            this.conversations = [];
            this.results = [];
            this.selectedConversation = null;
            //  Fade out ConversationList:
            if (searchResults === null) {
                this.listSearchResults = false;
                //  Results cleared: re-fetch Conversations:
                this.fetchConversations();
            } else {
                //  Results found: update ConversationList:
                this.listSearchResults = true;
                //  Array for result sets (messages/users):
                let resultObjects = [];
                //  Users:
                let userResults = searchResults.users;
                userResults.forEach(function (userResult: any, index: number) {
                    userResult.cssClass = (index === 0) ? 'result result-user result-first' : 'result result-user';
                    userResult.lastMessageOutput = userResult.lastMessage.content;
                    userResult.highlightedMessage = userResult.lastMessage.id;
                    resultObjects.push(userResult);
                });
                //  Messages:
                let messageResults = searchResults.messages;
                messageResults.forEach(function (messageResult: any, index: number) {
                    messageResult.cssClass = (index === 0) ? 'result result-message result-first' : 'result result-message';
                    messageResult.lastMessageOutput = messageResult.lastMessage.content;
                    messageResult.highlightedMessage = messageResult.lastMessage.id;
                    resultObjects.push(messageResult);
                });
                this.results = resultObjects;
                //  Clear selectedConversation:
                this.setSelectedConversation(null);
            }
        }, 250);
    }

    // ----  LISTENERS:
    //  Listener for incoming 'Message is read' events, so we can emit an event to the ConversationList and ConversationWindow to mark the
    //  last outgoing Message as read if Sender and Receiver are currently in an active Chat Conversation:
    initMessageReadListener(): void {
        this.socketService.onReceiveMessageIsRead();
        //
        this.chatMessageReadObservable = this.socketService.onReceiveMessageIsRead(); // chatMessageReadObservable;
        this.chatMessageReadObservable
            .subscribe(
                (response) => {
                    this.logger.print('Receive \'Message read\' from receiver succeeded: ', response, 'info');
                    if (response.conversationId === this.selectedConversation.id) {
                        setTimeout(() => {
                            this.selectedConversation.lastMessage.isRead = true;
                        }, 1500);
                    }
                },
                (error) => {
                    console.log('Receive \'Message read\' from receiver failed: ', error);
                },
                () => {}
            );
    }
}
