import { HashLocationStrategy, LOCATION_INITIALIZED } from '@angular/common';
import { LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { MyDatePickerModule } from 'mydatepicker';
import { ngfModule } from 'angular-file';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';

import { ApiService } from '@services/api.service';
import { AuthenticationService } from '@services/authentication.service';
import { DateService } from '@services/date.service';
import { LoggingService } from '@services/logging.service';
import { SocketService } from '@services/socket.service';
import { UserService } from '@services/user.service';

import { AuthenticationGuard } from '@guards/authentication.guard';
import { AuthenticationInterceptor } from '@interceptors/authentication.interceptor';

import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { ChatModule } from '@module.chat/chat.module';
import { CoreModule } from '@module.core/core.module';
import { DashboardModule } from '@module.dashboard/dashboard.module';
import { IncidentModule } from '@module.incident/incident.module';
import { PlaygroundModule } from '@module.playground/playground.module';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/' , '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            const langToSet = 'nl';
            translate.setDefaultLang(langToSet);
            translate.use(langToSet)
                .subscribe(
                    () => {
                        console.log('Initializing language succeeded: ' + langToSet);
                    },
                    (error) => {
                        console.log('Initializing language failed: ' + langToSet + '\n', error);
                    },
                    () => {
                        resolve(null);
                    }
                );
        });
    });
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        ChatModule,
        CoreModule,
        DashboardModule,
        IncidentModule,
        PlaygroundModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DeviceDetectorModule.forRoot(),
        MyDatePickerModule,
        ngfModule,
        MalihuScrollbarModule.forRoot(),
        NgSelectModule,
        PasswordStrengthBarModule,
        SweetAlert2Module.forRoot({
            cancelButtonClass: 'button secondary',
            confirmButtonClass: 'button primary',
            customClass: 'swal-content',
            buttonsStyling: false
        }),
        ToastrModule.forRoot({
            maxOpened: 3
        })
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        AuthenticationGuard,
        AuthenticationService,
        ApiService,
        DateService,
        LoggingService,
        SocketService,
        UserService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
            multi: false
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
