import { Component } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { OnInit } from '@angular/core';

import { ChatService } from '../chat.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'hss-chat-search',
    templateUrl: 'chat-search.component.html'
})

export class ChatSearchComponent implements OnInit {
    @Output() searchResults = new EventEmitter<boolean>();
    public searchQuery = '';
    private minimumQueryLength = 3;
    public hasQuery = false;
    public searchResultsCount = 0;
    //
    private toastrOptions = {
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        timeOut: 5000
    } as any;

    constructor(
        private chatService: ChatService,
        private logger: LoggingService,
        private toastr: ToastrService,
        public translate: TranslateService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        this.hasQuery = (this.searchQuery.length > 0);
    }

    submitSearch(): void {
        //  Is search query valid (at least 3 characters)?
        if (this.searchQuery && this.searchQuery.length >= this.minimumQueryLength) {
            //  Yes => Perform search:
            this.doSubmitSearch();
        } else {
            //  No => Show feedback:
            //  No => Show feedback:
            let message = this.translate.instant('STRING.CHAT.SEARCH.TOO_SHORT', { 'minimumQueryLength': this.minimumQueryLength});
            let title = this.translate.instant('STRING.CHAT.SEARCH.INVALID');
            this.toastr.error(message, title, this.toastrOptions);
        }
    }

    /** @namespace resultObjs.users **/
    doSubmitSearch(): void {
        //  Request:
        let searchMessagesObservable = this.chatService.searchMessages(this.searchQuery);
        searchMessagesObservable
            .subscribe(
            (response) => {
                this.logger.print('Search Messages succeeded: ', response, 'log');
                //  Store all IncidentUsers in data-bound Array:
                let resultObjs = response as any;
                //  Search results found?
                if (resultObjs.messages.length > 0 || resultObjs.users.length > 0) {
                    this.searchResultsCount = resultObjs.messages.length + resultObjs.users.length;
                    //  Yes => Emit searchResults to parent (parent uses this collection to re-populate ConversationList with SearchResults):
                    this.searchResults.emit(resultObjs);
                    // this.hasQuery = true;
                } else {
                    this.searchResultsCount = 0;
                    //  No => Show feedback:
                    let message = this.translate.instant('STRING.CHAT.SEARCH.NOT_FOUND');
                    let title = this.translate.instant('STRING.NO_RESULTS');
                    this.toastr.error(message, title, this.toastrOptions);
                }
            },
            (error) => {
                this.logger.print('Search Messages failed: ', error, 'error');
                this.searchResultsCount = 0;
                let message = this.translate.instant('STRING.CHAT.SEARCH.ERROR.UNKNOWN');
                let title = this.translate.instant('STRING.ERROR');
                if (error.status) {
                    message = this.translate.instant('STRING.CHAT.SEARCH.ERROR.CODE', {error: error.status});
                }
                this.toastr.error(message, title, this.toastrOptions);
            },
            () => {}
        );
    }

    keyDownSearchMessages(event): void {
        if (event.key === 'Enter') {
            this.submitSearch();
        }
    }

    keyUpSearchMessages(): void {
        this.hasQuery = (this.searchQuery.length > 2);
    }

    clearSearch(): void {
        //  If there are results, emit 'nothing' to parent (parent uses null value to reset ConversationList [ie. re-populating with all Conversations]):
        if (this.searchResultsCount > 0) {
            this.searchResults.emit(null);
        }
        //  Reset local variables:
        this.searchQuery = '';
        this.hasQuery = false;
    }
}
