import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';

import { DashboardCoreComponent } from './dashboard-core/dashboard-core.component';
import { DashboardNavigationComponent } from './dashboard-navigation/dashboard-navigation.component';
import { DashboardSidebarComponent } from './dashboard-sidebar/dashboard-sidebar.component';
import { DashboardUserInfoComponent } from './dashboard-userinfo/dashboard-userinfo.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { throwIfAlreadyLoaded } from '@guards/module-import.guard';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/' , '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AppRoutingModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        DashboardCoreComponent,
        DashboardNavigationComponent,
        DashboardSidebarComponent,
        DashboardUserInfoComponent
    ],
    declarations: [
        DashboardCoreComponent,
        DashboardNavigationComponent,
        DashboardSidebarComponent,
        DashboardUserInfoComponent
    ],
    providers: [],
})

export class DashboardModule {
    constructor( @Optional() @SkipSelf() parentModule: DashboardModule) {
        throwIfAlreadyLoaded(parentModule, 'PublicModule');
    }
}
