import { Component, Input } from '@angular/core';
import { OnInit, OnChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { DateService } from '@services/date.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IncidentService } from '../incident.service';
import { LoggingService } from '@services/logging.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import { Incident } from '../incident.model';
import { IncidentUser } from '../incident-user.model';

import swal from 'sweetalert2';

import { FadeAnimation, ToggleAnimation } from '@animations/animations';

@Component({
    selector: 'hss-incident-list-item',
    templateUrl: 'incident-list-item.component.html',
    animations: [FadeAnimation(125), ToggleAnimation()]
})

export class IncidentListItemComponent implements OnInit, OnChanges {
    @Input() incident: Incident;
    @Input() isTableView: boolean;
    currentUser: any;
    isVolunteer: boolean;
    incidentUsers: any = [];
    incidentRoles: any = [];
    openRequests: any = [];
    preselectedIncidentId: string = null; // URL parameter
    readonly maxDeclinedRequests: number = 3;
    //
    detailsOpen: string;
    detailsVisible: boolean;
    showAsTable: boolean;
    visibilityState: string;
    dataLoading = false;
    //
    isMobile: boolean;
    //
    sendRequestReason: string;
    declineRequestReason: string;
    //
    private toastrOptions = {
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        timeOut: 5000
    } as any;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dateService: DateService,
        private deviceService: DeviceDetectorService,
        private incidentService: IncidentService,
        private logger: LoggingService,
        private toastr: ToastrService,
        public translate: TranslateService,
        private userService: UserService
    ) {
        //  Check for urlParameter 'id' (http://{domain}/dashboard/incidents/:id), so we can open the correct Incident onLoad:
        this.preselectedIncidentId = this.activatedRoute.snapshot.paramMap.get('id');
    }

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
        this.isVolunteer = this.userService.isVolunteer();
        this.getUserRoles();
        //  Hide details onLoad:
        this.detailsVisible = false;
        this.detailsOpen = 'close';
        //  Visibility State of Incident Details:
        this.visibilityState = 'visible';
        //  Are we dealing with a mobile device (or a desktop with a resolution < 640p)?
        this.isMobile = this.deviceService.isMobile();
        if (!this.isMobile && window.innerWidth <= 640) {
            this.isMobile = true;
        }
        //  Open pre-selected (from url) or first list item onInit:
        if (this.preselectedIncidentId) {
            if (this.preselectedIncidentId === this.incident.id.toString()) {
                //  Open pre-selected IncidentDetails:
                this.toggleVisibility();
                let self = this;
                //  Scroll to pre-selected Incident (delayed) and use 'highlight' css animation to focus attention on Incident:
                setTimeout( function () {
                    let el = document.getElementById('incident-' + self.preselectedIncidentId);
                    el.scrollIntoView({ behavior: 'smooth' });
                    el.classList.add('highlight');
                }, 1000);
            }
        } else {
            if (this.incident.index === 0) {
                this.toggleVisibility();
            } else {
                this.fetchIncidentUsers(false);
            }
        }
    }

    ngOnChanges(changes: { [propkey: string]: SimpleChange }): void {
        for (let propName in changes) {
            if (propName === 'isTableView') {
                this.toggleViewmode();
            }
        }
    }

    //  Get all IncidentRoles of User:
    getUserRoles(): void {
        //  Get all available UserRoles (only include Volunteer role if User is Volunteer):
        this.incidentRoles = this.userService.getUserIncidentRoles(this.isVolunteer);
    }

    //  Update User IncidentRole:
    doUpdateIncidentUserRole(): void {
        //  Request:
        let updateUserIncidentRoleObservable = this.incidentService.updateIncidentUserRole(this.incident.incidentRole, this.incident.incidentUserId);
        updateUserIncidentRoleObservable
            .subscribe(
            (response) => {
                this.logger.print('Update IncidentUser Role succeeded', response, 'log');
                let message = this.translate.instant('STRING.RESUSCITATION.ROLE.UPDATE.SUCCESS');
                let title = this.translate.instant('STRING.SUCCESS');
                this.toastr.success(message, title, this.toastrOptions);
            },
            (error) => {
                this.logger.print('Update IncidentUser Role failed', error, 'error');
                let message = this.translate.instant('STRING.RESUSCITATION.ROLE.UPDATE.ERROR.UNKNOWN');
                let title = this.translate.instant('STRING.ERROR');
                if (error.status) {
                    message = this.translate.instant('STRING.RESUSCITATION.ROLE.UPDATE.ERROR.CODE', {error: error.status});
                }
                this.toastr.error(message, title, this.toastrOptions);
            },
            () => {}
        );
    }

    //  Toggle visibility of details:
    toggleVisibility(): void {
        //  Cancel event if Incident data is currently being loaded:
        if (this.dataLoading) {
            return;
        }
        //  Is Incident data currently visible?
        if (!this.detailsVisible) {
            //  No => Fetch IncidentUsers:
            this.fetchIncidentUsers();
        } else {
            //  Incident data currently invisible => close details panel:
            this.detailsVisible = false;
            this.detailsOpen = 'close';
            //  Hide any error (if visible):
            this.feedbackVisible = false;
        }
    }

    //  Fetch IncidentUsers (on 'OpenIncidentDetails' event):
    fetchIncidentUsers(showDetails: boolean = true): void {
        let self = this;
        //  Reset collections:
        this.incidentUsers = [];
        // this.openRequests = [];
        //  Fetch IncidentUser data (API) before showing the details, if desired:
        if (showDetails) {
            this.dataLoading = true;
        }
        let incidentObservable = this.incidentService.getIncidentUsers(this.incident.id);
        incidentObservable
            .subscribe(
            (response) => {
                this.logger.print('Fetch IncidentUsers succeeded', response, 'log');
                let incidentUserObjs = response as any;
                //  Store all IncidentUsers in data-bound Array:
                incidentUserObjs.forEach(function (incidentUserObj) {

                    //  TODO (once response is fixed): remove TEMPORARY code that filters out all Users with status 'Deleted':
                    if (incidentUserObj.contactStatus.toLowerCase() !== 'deleted') {
                        //  Cast response data to IncidentUser Object, and add to IncidentUser array:
                        let incidentUser = new IncidentUser(incidentUserObj);
                        self.incidentUsers.push(incidentUser);
                    }
                });
                //  IncidentUsers found?
                if (this.incidentUsers.length === 0) {
                    //  No => Show feedback:
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.USERS.NOT_FOUND') + '</span>';
                    this.feedbackClass = 'warning';
                    this.feedbackVisible = true;
                } else {
                    //  Yes => Find all IncidentUsers with open ContactRequest:
                    this.openRequests = this.incidentUsers.filter(x => x.contactStatus === 'requested (in)');
                }
            },
            (error) => {
                this.logger.print('Fetch IncidentUsers failed', error, 'error');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.USERS.ERROR.CODE', {error: error.status}) + '</span>';
                } else {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.USERS.ERROR.UNKNOWN') + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
            },
            () => {
                //  Open Incident details panel, if desired (no matter failure or success, slightly delayed to allow API call to wrap up completely):
                if (showDetails) {
                    setTimeout(() => {
                        this.detailsVisible = true;
                        this.detailsOpen = 'open';
                        this.dataLoading = false;
                    }, 250);
                }
            });
    }

    //  Toggle View Mode:
    toggleViewmode(): void {
        this.visibilityState = 'hidden';
        setTimeout(() => {
            this.showAsTable = !this.showAsTable;
            this.visibilityState = 'visible';
        }, 375);
    }

    //  Send Contact Request:
    sendRequest(receiverUserIncident: IncidentUser): void {
        let self = this;
        let title = this.translate.instant('STRING.CONTACT.REQUEST.SEND');
        let text = this.translate.instant('STRING.CONTACT.REQUEST.SEND.TEXT', { 'name': receiverUserIncident.fullName });
        let placeholder = this.translate.instant('STRING.CONTACT.REQUEST.SEND.PLACEHOLDER');
        //  Initialize SweetAlert:
        swal.fire({
            customClass: 'swal-content',
            input: 'textarea',
            inputPlaceholder: placeholder,
            title: title,
            html: text,
            type: 'info',
            cancelButtonClass: 'button secondary float-left',
            cancelButtonText: this.translate.instant('STRING.ACTION.CANCEL'),
            showCancelButton: true,
            confirmButtonText: this.translate.instant('STRING.ACTION.OK'),
            confirmButtonClass: 'button primary float-right',
            buttonsStyling: false,
            preConfirm: function(value) {
                self.sendRequestReason = value;
            }
        }).then((value) => {
            if (!value.dismiss) {
                self.doSendRequest(receiverUserIncident);
            }
        });
    }

    doSendRequest(receiverUserIncident: IncidentUser): void {
        //  Request:
        let sendRequestObservable = this.incidentService.sendContactRequest(this.sendRequestReason, receiverUserIncident);
        sendRequestObservable
            .subscribe(
            (response) => {
                this.logger.print('Send ContactRequest succeeded: ', response, 'log');
                //  Update affected IncidentUser.status in UserIncidents array to 'requested (out)', so changes in status become visible on screen:
                let userIncidentIndex = this.incidentUsers.findIndex((obj => obj.userIncident === receiverUserIncident.userIncident));
                this.incidentUsers[userIncidentIndex].contactStatus = 'requested (out)';
            },
            (error) => {
                this.logger.print('Send ContactRequest failed: ', error, 'error');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.REQUEST.SEND.ERROR.CODE', {error: error.status}) + '</span>';
                } else {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.REQUEST.SEND.ERROR.UNKNOWN') + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
            },
            () => {}
        );
    }

    //  Confirm Contact Request:
    confirmRequest(receiverUserIncident: IncidentUser): void {
        // let self = this;
        let title = this.translate.instant('STRING.CONTACT.REQUEST.CONFIRM');
        let text = this.translate.instant('STRING.CONTACT.REQUEST.CONFIRM.TEXT', { 'name' : receiverUserIncident.fullName });
        //  Initialize SweetAlert:
        swal.fire({
            customClass: 'swal-content no-icon',
            title: title,
            html: '<p>' + text + '</p>',
            type: 'info',
            showCancelButton: true,
            cancelButtonClass: 'button secondary float-left',
            cancelButtonText: this.translate.instant('STRING.OKAY.CLOSE'),
            confirmButtonClass: 'button primary float-right',
            confirmButtonText: this.translate.instant('STRING.MESSAGE.SEND'),
            buttonsStyling: false
        }).then((e) => {
            //  Submit Confirmation:
            let doRedirect = (e.value === true);
            this.doConfirmRequest(receiverUserIncident, doRedirect);
        });
    }

    doConfirmRequest(receiverUserIncident: IncidentUser, doRedirect: boolean): void {
        //  Request:
        let confirmRequestObservable = this.incidentService.confirmContactRequest(receiverUserIncident);
        confirmRequestObservable
            .subscribe(
            (response) => {
                this.logger.print('Confirm ContactRequest succeeded: ', response, 'log');
                //  Update User 'contactRequestCount' property:
                this.currentUser.contactRequestCount = this.currentUser.contactRequestCount - 1;
                this.userService.setUser(this.currentUser);
                //  Redirect User to Chat?
                if (doRedirect) {
                    //  Yes => Redirect to Chat and open Conversation with ConversationId:
                    let conversationId = response['id'];
                    let navigationExtras: NavigationExtras = { state: { conversationId: conversationId }};
                    this.router.navigate(['/dashboard/inbox'], navigationExtras);
                } else {
                    //  No => Close SweetAlert and stay on Incidents page:
                    //  Update affected IncidentUser.status in 'UserIncidents' array to 'confirmed', so changes in status become visible on screen:
                    let userIncidentIndex = this.incidentUsers.findIndex(obj => obj.userIncident === receiverUserIncident.userIncident);
                    this.incidentUsers[userIncidentIndex].contactStatus = 'confirmed';
                    //  Remove affected IncidentUser.status from 'OpenRequests' array, so changes in status become visible on screen:
                    let openRequestsIndex = this.openRequests.findIndex(obj => obj.userIncident === receiverUserIncident.userIncident);
                    this.openRequests.splice(openRequestsIndex, 1);
                }
            },
            (error) => {
                this.logger.print('Confirm ContactRequest failed: ', error, 'error');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.REQUEST.CONFIRM.ERROR.CODE', {error: error.status}) + '</span>';
                } else {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.REQUEST.CONFIRM.ERROR.UNKNOWN') + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
            },
            () => {}
        );
    }

    //  Decline Contact Request:
    declineRequest(receiverUserIncident: IncidentUser): void {
        let self = this;
        let title = this.translate.instant('STRING.CONTACT.REQUEST.DECLINE');
        let text = this.translate.instant('STRING.CONTACT.REQUEST.DECLINE.TEXT', { 'name' : receiverUserIncident.fullName });
        let placeholder = this.translate.instant('STRING.CONTACT.REQUEST.DECLINE.PLACEHOLDER');
        //  Initialize SweetAlert:
        swal.fire({
            customClass: 'swal-content',
            input: 'textarea',
            inputPlaceholder: placeholder,
            title: title,
            html: text,
            type: 'info',
            cancelButtonText: this.translate.instant('STRING.ACTION.CANCEL'),
            cancelButtonClass: 'button secondary float-left',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('STRING.ACTION.OK'),
            confirmButtonClass: 'button primary float-right',
            buttonsStyling: false,
            preConfirm: function(value) {
                self.declineRequestReason = value;
            }
        }).then((value) => {
            if (!value.dismiss) {
                self.doDeclineRequest(receiverUserIncident);
            }
        });
    }

    doDeclineRequest(receiverUserIncident: IncidentUser): void {
        //  Request:
        let declineRequestObservable = this.incidentService.declineContactRequest(this.declineRequestReason, receiverUserIncident);
        declineRequestObservable
            .subscribe(
            (response) => {
                this.logger.print('Decline ContactRequest succeeded: ', response, 'log');
                //  Update User 'contactRequestCount' property:
                this.currentUser.contactRequestCount = this.currentUser.contactRequestCount - 1;
                this.userService.setUser(this.currentUser);
                //  Update affected IncidentUser.status in 'UserIncidents' array to 'open', so changes in status become visible on screen:
                let userIncidentIndex = this.incidentUsers.findIndex(obj => obj.userIncident === receiverUserIncident.userIncident);
                this.incidentUsers[userIncidentIndex].contactStatus = 'declined (out)';
                this.incidentUsers[userIncidentIndex].fullName = null;
                //  Remove affected IncidentUser.status from 'OpenRequests' array, so changes in status become visible on screen:
                let openRequestsIndex = this.openRequests.findIndex(obj => obj.userIncident === receiverUserIncident.userIncident);
                this.openRequests.splice(openRequestsIndex, 1);
            },
            (error) => {
                this.logger.print('Decline ContactRequest failed: ', error, 'error');
                if (error.status) {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.REQUEST.DECLINE.ERROR.CODE', {error: error.status}) + '</span>';
                } else {
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.CONTACT.REQUEST.DECLINE.ERROR.UNKNOWN') + '</span>';
                }
                this.feedbackClass = 'error';
                this.feedbackVisible = true;
            },
            () => {}
        );
    }

    //  Send message (ie. redirect to Chat and open Conversation with ConversationId):
    sendMessage(receiverUserIncident: IncidentUser): void {
        //  Redirect to Chat page with correct Conversation opened onLoad:
        let navigationExtras: NavigationExtras = { state: { conversationId: receiverUserIncident.conversationId }};
        this.router.navigate(['/dashboard/inbox'], navigationExtras);
    }

    //  Navigate (with NavigationExtras):
    navigate(route: string, conversationId: number): void {
        let navigationExtras: NavigationExtras = { state: { conversationId: conversationId }};
        this.router.navigate(['/' + route], navigationExtras);
    }
}
