import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { PluginsComponent } from './plugins/plugins.component';
import { StyleGuideComponent } from './styleguide/styleguide.component';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MyDatePickerModule } from 'mydatepicker';
import { ngfModule } from 'angular-file';
import { NgSelectModule } from '@ng-select/ng-select';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { throwIfAlreadyLoaded } from '@guards/module-import.guard';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/' , '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MyDatePickerModule,
        ngfModule,
        NgSelectModule,
        PasswordStrengthBarModule,
        SweetAlert2Module
    ],
    exports: [
        PluginsComponent,
        StyleGuideComponent
    ],
    declarations: [
        PluginsComponent,
        StyleGuideComponent
    ],
    providers: [],
})

export class PlaygroundModule {
    constructor( @Optional() @SkipSelf() parentModule: PlaygroundModule) {
        throwIfAlreadyLoaded(parentModule, 'PublicModule');
    }
}
