import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { IncidentService } from '../incident.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import * as moment from 'moment';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

@Component({
    selector: 'hss-incident-register-without-code',
    templateUrl: 'incident-register-without-code.component.html'
})

export class IncidentRegisterWithoutCodeComponent implements OnInit {
    currentUser: any;
    isVolunteer: boolean;
    incidentRole: string;
    incidentRoles: any = [];
    selectedDate: any;
    incidentDate: any;
    incidentCity: string;
    incidentAddress: string;
    employer: string;
    //
    public datePickerLocale: string;
    public datePickerOptions: IMyDpOptions;
    //
    isSubmitting = false;
    //
    feedbackClass: string;
    feedbackMessage: string;
    feedbackVisible = false;

    constructor(
        private router: Router,
        private incidentService: IncidentService,
        private logger: LoggingService,
        public translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
        this.isVolunteer = this.userService.isVolunteer();
        //  Init. DatePicker:
        let nowDate = moment.utc().local();
        this.datePickerLocale = this.translate.currentLang;
        this.datePickerOptions = {
            dateFormat: 'dd-mm-yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            sunHighlight: false,
            disableSince: { year: nowDate.year(), month: nowDate.month() + 1, day: nowDate.date() + 1 },
        };
        //  Must use timeout for translations to apply (even if set to ridiculously small value like 25ms), just 'cause:
        setTimeout(() => {
            this.getUserRoles();
        }, 25);
    }

    //  Get all available UserRoles (except Volunteer role):
    getUserRoles(): void {
        this.incidentRoles = this.userService.getUserIncidentRoles(false);
    }

    onIncidentRoleChanged(selectedRole): void {
        if (selectedRole !== 'professional') {
            this.employer = '';
        }
    }

    onDateChanged(event: IMyDateModel): void {
        //  Prepare selected Date for payload:
        this.incidentDate = event.jsdate;
        this.selectedDate = event.formatted;
    }

    onSubmitRequest(): void {
        let field;
        let feedback = '';
        this.feedbackVisible = false;
        this.feedbackMessage = '';
        //  Input validation:
        if (!this.incidentRole) {
            field = this.translate.instant('STRING.RESUSCITATION.ROLE');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.incidentDate) {
            field = this.translate.instant('STRING.RESUSCITATION.DATE');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.incidentCity) {
            field = this.translate.instant('STRING.RESUSCITATION.CITY');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (!this.incidentAddress) {
            field = this.translate.instant('STRING.RESUSCITATION.STREET_HOUSENUMBER');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        if (this.incidentRole === 'professional' && !this.employer) {
            field = this.translate.instant('STRING.RESUSCITATION.SECURITY.REGION');
            feedback += '<span>' + this.translate.instant('FEEDBACK.NOT_FILLED_IN', {'field': field}) + '</span>';
            this.feedbackVisible = true;
        }
        //
        if (this.feedbackVisible) {
            this.feedbackClass = 'error';
            this.feedbackMessage = feedback;
        } else {
            this.doSubmitRequest();
        }
    }

    doSubmitRequest(): void {
        let incidentDateParsed = new Date(this.incidentDate);
        incidentDateParsed.setHours(12);
        let postData = {
            firstName: this.currentUser.firstName,
            middleName: this.currentUser.middleName,
            lastName: this.currentUser.lastName,
            email: this.currentUser.email,
            employer: this.employer,   // dafuq this come from?
            incidentRole: this.incidentRole,
            incidentDate: incidentDateParsed,
            incidentCity: this.incidentCity,
            incidentAddress: this.incidentAddress
        };
        //  Request:
        this.isSubmitting = true;
        let requestIncidentCodeObservable = this.incidentService.requestIncidentCode(postData);
        requestIncidentCodeObservable
            .pipe(finalize(() =>
                setTimeout(() => {
                    this.isSubmitting = false;
                }, 500)
            ))
            .subscribe(
                (response) => {
                    this.logger.print('Requesting unique code succeeded: ', response, 'log');
                    this.feedbackClass = 'success';
                    this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.REGISTER.WITHOUT_CODE.SUBMIT.SUCCESS') + '</span>';
                    this.feedbackVisible = true;
                    //  Clear form:
                    this.incidentRole = null;
                    this.incidentDate = '';
                    this.selectedDate = '';
                    this.incidentCity = '';
                    this.incidentAddress = '';
                },
                (error) => {
                    this.logger.print('Requesting unique code failed: ', error, 'error');
                    if (error.status) {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.REGISTER.WITHOUT_CODE.SUBMIT.ERROR.CODE', {error: error.status}) + '</span>';
                    } else {
                        this.feedbackMessage = '<span>' + this.translate.instant('STRING.INCIDENT.REGISTER.WITHOUT_CODE.SUBMIT.ERROR.UNKNOWN') + '</span>';
                    }
                    this.feedbackClass = 'error';
                    this.feedbackVisible = true;
                },
                () => {}
            );
    }

    navigate(route: string): void {
        this.router.navigate(['/' + route]);
    }
}
