/// <reference types="@types/googlemaps" />

import { Component, ElementRef } from '@angular/core';
import { AfterContentInit, OnInit, OnDestroy } from '@angular/core';
import { ViewChild } from '@angular/core';

import { take } from 'rxjs/operators';

import { ApiService } from '@services/api.service';
import { DateService } from '@services/date.service';
import { LoggingService } from '@services/logging.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user.service';

import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import swal from 'sweetalert2';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'hss-plugins',
    templateUrl: 'plugins.component.html'
})

export class PluginsComponent implements OnInit, AfterContentInit, OnDestroy {
    //  User:
    currentUser: any;
    //  DateService:
    public currentDateServiceDate: any;
    public currentDateServiceDates: any = [];
    //  Google Maps:
    @ViewChild('gmap', {static: true}) gmapElement: ElementRef;
    map: google.maps.Map;
    mapTypeId = 'roadmap';
    mapOptions: google.maps.MapOptions = {};
    //  ImageUpload (angular-file):
    accept = 'image/*';
    dragFiles: File[] = [];
    files: File[] = [];
    currentFileUpload: any;
    currentFileUploadPath: string;
    maxUploadSize = 2097162; // 2MB in bytes
    validDrag: boolean;
    isSubmittingAvatar = false;
    //  MyDatePicker:
    public currentDatePickerDate: any;
    public datePickerLocale: string;
    public datePickerIconOptions: IMyDpOptions;
    public datePickerIconTextboxOptions: IMyDpOptions;
    public datePickerPastOptions: IMyDpOptions;
    public datePickerFutureOptions: IMyDpOptions;
    public datePickerRangeOptions: IMyDpOptions;
    //  ng2-password-strength-bar:
    public strengthbarColorsPassword: any;
    public strengthbarLabelsPassword: any;
    public strengthbarBaseColor: string;
    public strengthbarColors: any;
    public strengthbarLabel: string;
    public strengthbarLabels: any;
    public strengthbarValid = false;
    public strengthbarValidThreshold = 3;
    //  ng-Select:
    public dropdownObjects: any;
    public selectedDropdownObject: any;
    public selectedDropdownObjects: any;
    //  ngx-Device-Detector:
    public deviceInfo: any;
    public isMobile: any = false;
    public isTablet = false;
    public isDesktop = false;
    //  ngx-Malihu-Scrollbar:
    private scrollbar1Options = {
        axis: 'y',
        theme: 'dark',
        alwaysShowScrollbar: 1,
        mouseWheel: { enable: true },
        scrollButtons: { enable: false }
    } as any;
    private scrollbar2Options = {
        axis: 'y',
        theme: 'inset-2-dark',
        alwaysShowScrollbar: 1,
        mouseWheel: { enable: true },
        scrollButtons: { enable: true }
    } as any;
    private scrollbar3Options = {
        axis: 'y',
        theme: 'custom-theme',
        alwaysShowScrollbar: 1,
        mouseWheel: { enable: true },
        scrollButtons: { enable: false }
    } as any;
    private scrollbar4Options = {
        axis: 'x',
        theme: 'dark-2',
        alwaysShowScrollbar: 1,
        mouseWheel: { enable: true },
        scrollButtons: { enable: true }
    } as any;
    private scrollbar5Options = {
        axis: 'yx',
        theme: 'custom-theme',
        alwaysShowScrollbar: 1,
        mouseWheel: { enable: true },
        scrollButtons: { enable: false }
    } as any;
    //  ngx-SweetAlert2:
    @ViewChild('infoSwal', {static: false}) infoSwal: ElementRef;
    private infoSwalObject: SwalComponent;
    //  ngx-Toastr:
    toastrOptions = {
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        timeOut: 0
    };
    //  Localization:
    public currentLanguage: string;

    constructor(
        private apiService: ApiService,
        public dateService: DateService,
        private deviceService: DeviceDetectorService,
        private logger: LoggingService,
        private mScrollbarService: MalihuScrollbarService,
        private toastr: ToastrService,
        public translate: TranslateService,
        private userService: UserService
    ) {}

    // ----  LIFECYCLE:
    ngOnInit(): void {
        //  Load Profile data:
        this.currentUser = this.userService.getUser();
        this.currentFileUpload = this.currentUser.image;

        //  MyDatePicker-related:
        this.currentDatePickerDate = this.dateService.getDatePickerNowObj();
        this.datePickerLocale = this.translate.currentLang;
        this.datePickerIconOptions = {
            dateFormat: 'dd-mm-yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            sunHighlight: false
        };
        this.datePickerIconTextboxOptions = {
            dateFormat: 'dd-mm-yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            sunHighlight: false
        };
        let rangeAndLimitDate = moment.utc().local();
        this.datePickerPastOptions = {
            dateFormat: 'dd-mm-yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            sunHighlight: false,
            disableSince: { year: rangeAndLimitDate.year(), month: rangeAndLimitDate.month() + 1, day: rangeAndLimitDate.date() },
        };
        this.datePickerFutureOptions = {
            dateFormat: 'dd-mm-yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            sunHighlight: false,
            disableUntil: { year: rangeAndLimitDate.year(), month: rangeAndLimitDate.month() + 1, day: rangeAndLimitDate.date() },
        };
        this.datePickerRangeOptions = {
            dateFormat: 'dd-mm-yyyy',
            editableDateField: false,
            openSelectorOnInputClick: true,
            sunHighlight: false,
            disableUntil: { year: rangeAndLimitDate.year(), month: rangeAndLimitDate.month(), day: rangeAndLimitDate.date() },
            disableSince: { year: rangeAndLimitDate.year(), month: rangeAndLimitDate.month() + 2, day: rangeAndLimitDate.date() }
        };

        //  DateService-related:
        this.currentDateServiceDate = new Date();
        this.currentDateServiceDates['MinutesAgo'] = new Date().setMinutes(this.currentDateServiceDate.getMinutes() - 5);
        this.currentDateServiceDates['HoursAgo'] = new Date().setHours(this.currentDateServiceDate.getHours() - 5);
        this.currentDateServiceDates['DaysAgo'] = new Date().setDate(this.currentDateServiceDate.getDate() - 5);
        this.currentDateServiceDates['MonthsAgo'] = new Date().setMonth(this.currentDateServiceDate.getMonth() - 5);
        this.currentDateServiceDates['YearsAgo'] = new Date().setFullYear(this.currentDateServiceDate.getFullYear() - 5);

        //  ng2-password-strength-bar:
        this.strengthbarColors = ['#EE3425', '#F79A92', '#FAB66D', '#9DCF75', '#629935'];
        this.strengthbarBaseColor = '#FFFFFF';
        //  Must use timeout for translations to apply (even if set to ridiculously small value like 25ms), just 'cause:
        setTimeout(() => {
            this.strengthbarLabel = this.translate.instant('STRING.PASSWORD.STRENGTH') + ':';
            this.strengthbarLabels = [
                '(' + this.translate.instant('STRING.PASSWORD.STRENGTH.1') + ')',
                '(' + this.translate.instant('STRING.PASSWORD.STRENGTH.2') + ')',
                '(' + this.translate.instant('STRING.PASSWORD.STRENGTH.3') + ')',
                '(' + this.translate.instant('STRING.PASSWORD.STRENGTH.4') + ')',
                '(' + this.translate.instant('STRING.PASSWORD.STRENGTH.5') + ')'
            ];
            this.strengthbarColorsPassword = null;
            this.strengthbarLabelsPassword = null;
        }, 25);

        // ngSelect-related:
        this.dropdownObjects = [
            {id: 'ID 01', name: 'Option 01', value: 'Value 01'},
            {id: 'ID 02', name: 'Option 02: option with a longer text', value: 'Value 02'},
            {id: 'ID 03', name: 'Option 03: option with a much, much, much, much longer text', value: 'Value 03'}
        ];

        //  ngx-Device-Detector-related:
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktop = this.deviceService.isDesktop();

        //  Localization-related:
        this.currentLanguage = this.userService.getUserLanguage();
    }

    ngAfterContentInit(): void {
        //  Google Maps:
        //  Map options:
        this.mapOptions = {
            center: new google.maps.LatLng(53.218035, 6.566572),
            zoom: 14,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        //  Init. map:
        this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapOptions);
        //  Show Markers (on load):
        this.showCustomMarkersOnLoad();
        //  Init. ngx-malihu-scrollbars:
        this.mScrollbarService.initScrollbar('#scrollbar1', this.scrollbar1Options);
        this.mScrollbarService.initScrollbar('#scrollbar2', this.scrollbar2Options);
        this.mScrollbarService.initScrollbar('#scrollbar3', this.scrollbar3Options);
        this.mScrollbarService.initScrollbar('#scrollbar4', this.scrollbar4Options);
        this.mScrollbarService.initScrollbar('#scrollbar5', this.scrollbar5Options);
        //  Auto-scroll select ngx-malihu-scrollbars:
        this.mScrollbarService.scrollTo('#scrollbar2', 'bottom', { scrollEasing: 'easeInOut', timeout: 1000 });
        this.mScrollbarService.scrollTo('#scrollbar4', 'right', { scrollEasing: 'easeOut', timeout: 1000 });
    }

    ngOnDestroy() {
        //  Destroy ngx-malihu-scrollbar instances:
        this.mScrollbarService.destroy('#scrollbar1');
        this.mScrollbarService.destroy('#scrollbar2');
        this.mScrollbarService.destroy('#scrollbar3');
        this.mScrollbarService.destroy('#scrollbar4');
        this.mScrollbarService.destroy('#scrollbar5');
    }


    // ----  GOOGLE MAPS Functions:
    //  Change Map Type:
    setMapType(mapTypeId: string) {
        this.mapTypeId = mapTypeId;
        this.map.setMapTypeId(mapTypeId);
    }
    //  Handle 'MarkerClick' event (simple [no marker ref):
    simpleMarkerClickHandler() {
        console.log('Marker clicked (simpleMarkerClickHandler)');
    }
    //  Handle 'MarkerClick' event [has Marker ref]:
    markerClickHandler(marker: google.maps.Marker, showInfoWindow: boolean = false) {
        this.map.setCenter(marker.getPosition());
        console.log('Marker \'' + marker.getTitle() + '\' clicked (markerClickHandler)');
        //  Show InfoWindow, if requested:
        if (showInfoWindow) {
            let infowindow = new google.maps.InfoWindow({content: marker.getTitle()});
            infowindow.open(this.map, marker);
        }
    }
    //  Show custom Markers (on load):
    showCustomMarkersOnLoad() {
        let self = this;
        let markers = [];
        let markerData = [
            {
                position: new google.maps.LatLng(53.215134, 6.568759),
                map: this.map, icon: '/assets/images/maps/marker_alarm.png',
                title: 'OnLoad Marker 1'
            },
            {
                position: new google.maps.LatLng(53.221147, 6.562962),
                map: this.map,
                icon: '/assets/images/maps/marker_location.png', title: 'OnLoad Marker 2'
            }];
        //  Init. Markers:
        markerData.forEach( function (data, index) {
            if (index === 0) {
                self.map.setCenter(data.position);
            }
            markers[index] = new google.maps.Marker(data);
            //  Bind Marker click event (write Marker title to console):
            markers[index].addListener('click', self.simpleMarkerClickHandler);
            //  Bind Marker click event (center map at clicked Marker):
            markers[index].addListener('click', () => {
                self.markerClickHandler(markers[index]);
            });
        });
    }
    //  Show custom Markers (from event):
    showCustomMarkersOnClick() {
        let self = this;
        let markers = [];
        let markerData = [
            {
                position: new google.maps.LatLng(53.219230, 6.568814),
                map: this.map, icon: '/assets/images/maps/marker_volunteer.png',
                title: 'OnClick Marker 1 (with InfoWindow)'
            },
            {
                position: new google.maps.LatLng(53.216384, 6.561765),
                map: this.map,
                icon: '/assets/images/maps/marker_volunteer_accepted.png', title: 'OnClick Marker 2 (with InfoWindow)'
            }];
        //  Init. Markers:
        markerData.forEach( function (data, index) {
            if (index === 0) {
                self.map.setCenter(data.position);
            }
            markers[index] = new google.maps.Marker(data);
            //  Bind Marker click event (write Marker title to console):
            markers[index].addListener('click', self.simpleMarkerClickHandler);
            //  Bind Marker click event (center map at clicked Marker):
            markers[index].addListener('click', () => {
                self.markerClickHandler(markers[index], true);
            });
        });
    }


    // ----  IMAGEUPLOAD (ANGULAR-FILE) Functions:
    onUploadAvatar(files): void {
        if (files.length > 0) {
            this.doUploadAvatar(files[0]);
        }
    }

    doUploadAvatar(avatar): void {
        console.log('Pretending to upload avatar: ', avatar);
        this.isSubmittingAvatar = true;
        setTimeout( () => {
            this.isSubmittingAvatar = false;
        }, 1250);
        //  Upload avatar logic here:
    }

    onRemoveAvatar(): void {
        //  Add confirm?
        this.doRemoveAvatar();
    }

    doRemoveAvatar(): void {
        //  Remove avatar logic here:
    }

    //  Angular-file related (file upload):
    onAvatarChanged(files): void {
        if (files.length === 0) {
            return;
        }
        let reader = new FileReader();
        this.currentFileUploadPath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.currentFileUpload = reader.result;
        };
    }

    onRemoveSelectedAvatar($event): void {
        $event.stopPropagation();
        //
        this.files = [];
        this.currentFileUpload = this.currentUser.image;
    }

    getMugshotStyle(): object {
        return { 'background-image': 'url(' + this.currentFileUpload + ')' };
    }


    // ----  NG2-PASSWORD-STRENGTH-BAR Functions:
    onPasswordStrengthChanged(passwordStrength): void {
        this.strengthbarValid = (passwordStrength >= this.strengthbarValidThreshold);
    }


    // ----  NGX-DEVICE-DETECTOR Functions:


    // ----  NGX-MALIHU-SCROLLBAR Functions:


    // ----  NGX-SWEETALERT2 Functions:
    //  Handle 'ButtonClick' in SweetAlert button:
    sweetAlertButtonClicked(action): void {
        if (action === 'ok') {
            this.logger.print('SWEET ALERT \'OK\'-BUTTON CLICKED!', '', 'log');
        }
        if (action === 'cancel') {
            this.logger.print('SWEET ALERT \'CANCEL\'-BUTTON CLICKED!', '', 'log');
        }
    }

    //  Open SweetAlert programmatically with Swal Component in View (open/close logic in Controller):
    openSweetAlertProgrammatically(): void {
        this.infoSwalObject.show();
    }

    //  Open SweetAlert programmatically (init. in Controller):
    openSweetAlertWithSwalComponent(): void {
        swal.fire({
            customClass: 'swal-content',
            title: 'Info',
            text: 'This is a SweetAlert modal of the type \'info\'',
            type: 'info',
            cancelButtonText: this.translate.instant('STRING.ACTION.CANCEL'),
            cancelButtonClass: 'button secondary float-left',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('STRING.ACTION.OK'),
            confirmButtonClass: 'button primary float-right',
            buttonsStyling: false
        });
    }

    openSweetAlertWithoutIcon(): void {
        swal.fire({
            customClass: 'swal-content no-icon',
            title: 'Info',
            text: 'This is a SweetAlert modal of the custom type \'no icon\'',
            type: 'info',
            showCancelButton: false,
            confirmButtonClass: 'button primary',
            buttonsStyling: false
        });
    }


    // ----  NGX-TOASTR Functions:
    serveToast(type: string, title: string, message: string): void {
        switch (type) {
            case 'info':
                this.toastr.info(message, title, this.toastrOptions);
                break;
            case 'success':
                this.toastr.success(message, title, this.toastrOptions);
                break;
            case 'warning':
                this.toastr.warning(message, title, this.toastrOptions);
                break;
            case 'error':
                this.toastr.error(message, title, this.toastrOptions);
                break;
            default:
                this.toastr.info(message, title, this.toastrOptions);
                break;
        }
    }

    serveToastWithProgressBar(isIncreasing: boolean): void {
        let message = (isIncreasing) ? 'This is a Toastr message with an increasing progress bar, auto-closing after 5 seconds.' :
            'This is a Toastr message with a decreasing progress bar, auto-closing after 5 seconds.';
        let title = 'Toastr message';
        let progressDirection = (isIncreasing) ? 'increasing' : 'decreasing';
        let toastrOptions = {
            closeButton: true,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            progressBar: true,
            progressAnimation: progressDirection,
            timeOut: 5000
        } as any;
        this.toastr.info(message, title, toastrOptions);
    }

    serveToastTopLeft(): void {
        let message = 'This is a Toastr message positioned at the top, left side.';
        let title = 'Toastr message';
        let toastrOptions = {
            closeButton: true,
            positionClass: 'toast-top-left',
            preventDuplicates: true,
            timeOut: 0
        };
        this.toastr.info(message, title, toastrOptions);
    }

    serveToastBottomRight(): void {
        let message = 'This is a Toastr message positioned at the bottom, right side.';
        let title = 'Toastr message';
        let toastrOptions = {
            closeButton: true,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            timeOut: 0
        };
        this.toastr.success(message, title, toastrOptions);
    }

    serveToastFullWidthTop(): void {
        let message = 'This is a full width Toastr message positioned at the top.';
        let title = 'Toastr message';
        let toastrOptions = {
            closeButton: true,
            positionClass: 'toast-top-full-width',
            preventDuplicates: true,
            timeOut: 0
        };
        this.toastr.warning(message, title, toastrOptions);
    }

    serveToastFullWidthBottom(): void {
        let message = 'This is a full width Toastr message positioned at the bottom.';
        let title = 'Toastr message';
        let toastrOptions = {
            closeButton: true,
            positionClass: 'toast-bottom-full-width',
            preventDuplicates: true,
            timeOut: 0
        };
        this.toastr.error(message, title, toastrOptions);
    }

    serveToastWithHtml(): void {
        let message = '<p>This is a Toastr message with html enabled in the body, like this text in a paragraph.</p>' +
            '<p><a href="">Or this hyperlink, also embedded in a paragraph.</a></p>' +
            '<ul><li>Or this unordered list with one item</li><li>Uhhh... obviously I meant two items...</li></ul>';
        let title = 'Toastr message with html';
        this.toastr.info(message, title, this.toastrOptions);
    }

    serveToastWithCallback(title: string, message: string): void {
        this.toastr.warning(message, title, this.toastrOptions)
            .onTap
            .pipe(take(1))
            .subscribe(() => this.onToastWithCallbackClicked());
    }

    onToastWithCallbackClicked(): void {
        alert('[callback handler] - Redirect User to Chat window');
    }


    // ----  LOCALIZATION Functions:
    //  Switch language
    switchLanguage(language: string): void {
        this.currentLanguage = language;
        this.userService.setUserLanguage(language);
        this.datePickerLocale = language;
    }
}
