import { NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PreventPropagationDirective } from '@directives/prevent-propagation.directive';

import { throwIfAlreadyLoaded } from '@guards/module-import.guard';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        PreventPropagationDirective
    ],
    declarations: [
        PreventPropagationDirective
    ],
    providers: [],
})

export class SharedModule {
    constructor( @Optional() @SkipSelf() parentModule: SharedModule) {
        throwIfAlreadyLoaded(parentModule, 'PublicModule');
    }
}
